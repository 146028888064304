import { ServiceController } from "../../../../services/controllers/global";
import { useService } from "../../../../store/global/useService";
import { Action } from "../../../ui/Action";

import { Alert } from "../../../ui/Alert";
import { Input } from "../../../ui/Form/Input";
import { Switch } from "../../../ui/Form/Switch";

export function ServiceEditAlert() {
  const CService = ServiceController()
  const service = useService((st) => st.data.service);

  return (
    <Alert.Root name="service-edit-alert">
      <Alert.Content className="!max-w-xl">
        <Alert.Title>Editar Serviço</Alert.Title>
        <div className="flex flex-col w-full  gap-5 mb-6">
          <div className="flex-1">
            <Input.Root
              money
              value={service.price}
              onChange={CService.setPrice}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p><Switch.Root name="menlo-term" className="!w-12" defaultChecked={service.status} onChange={CService.setStatus} label="Status do serviço" /></p>
          </div>
        </div>
        <Alert.Actions>
          <Action.Root type="button" onClick={CService.closeAlert} outline status="danger">Cancelar</Action.Root>
          <Action.Root type="button" onClick={CService.update}>Salvar</Action.Root>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root >
  )
}
