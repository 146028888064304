import moment from "moment";

import { formatMoney, formatName, getCurveColor, perc2color } from '../functions'

function formatRoyalty(royalty) {
  const acquirer = {
    taxId: !!royalty?.acquirer ? royalty.acquirer : '',
    name: !!royalty?.acquirer ? formatName(royalty.acquirer.name) : '',
    nickname: !!royalty?.acquirer ? formatName(royalty.acquirer.nickname) : '',
  }

  const payment = {
    name: !!royalty?.paymentScheme ? formatName(royalty.paymentScheme.name) : '',
    code: !!royalty?.paymentScheme ? royalty.paymentScheme.code : '',
    type: !!royalty?.paymentScheme ? royalty.paymentScheme.type : '',
  }

  return {
    id: royalty._id,
    createdAt: moment(royalty.settlementDate).format('DD/MM/YYYY'),
    amountToPay: royalty?.amountToPay || 0,
    netAmount: royalty?.netAmount || 0,
    acquirer,
    payment
  };
}

function formatPurchaseReport(report) {
  const quantitySold = Math.round(report.quantidadeVendida * 1000) / 1000
  const percent = Math.max(0, ((quantitySold - report.sugestaoCompra) / quantitySold) * 100);


  console.log('report',)
  return {
    description: formatName(report.descricao),
    stock: report.estoque,
    monthlyAverage: formatMoney(report.mediaMensal),
    department: formatName(report.nomeDepartamento),
    brand: formatName(report.nomeMarca),
    quantitySold: quantitySold,
    suggestionPurchase: report.sugestaoCompra,
    codigoBarras: report.codigoBarras,
    curva: report.curva !== "ZZ" ? report.curva : '-',
    curveColor: perc2color({ center: 70, percent })
  }
}

function formatPagination(pagination) {
  return {
    page: pagination?.pagina || pagination?.currentPage,
    totalPages: pagination?.totalPaginas || pagination?.totalPages,
    perPage: pagination?.porPagina || pagination?.limit,
    totalDocuments: pagination?.totalDocumentos || pagination.totalDocuments,
    full: pagination,
  };
}

export const formatResponse = {
  pagination: (pagination) => formatPagination(pagination),
  royalty: (royalty) => formatRoyalty(royalty),
  royalties: (royalties) => {
    if (!royalties.length) return [];
    return royalties.map(formatRoyalty);
  },
  purchaseReport: (report) => formatPurchaseReport(report),
  purchaseReports: (reports) => {
    if (!reports.length) return [];
    return reports.map(formatPurchaseReport);
  },
};
