import CountUp from "react-countup";
import { Summary } from "../../../../components/ui/Summary";
import { usePurchaseReports } from "../../../../store/reports/usePurchaseReport";


export function PurchaseReportHighlight() {
  const purchaseHighlights = usePurchaseReports((st) => st.data.purchaseHighlightReport);
  const totalHighlighted = usePurchaseReports((st) => st.data.highlighteds);

  const defaultData = new Array(totalHighlighted).fill({})

  const purchases = purchaseHighlights.length ? purchaseHighlights : defaultData;

  return (
    <Summary.Root>
      {purchases.map((purchase) => (
        <Summary.Box style={{ borderColor: purchase.curveColor }}>
          <span className="font-bold text-4xl" style={{ color: purchase.curveColor }}>
            {purchase?.curva || ''}
          </span>
          <Summary.TextContent>
            <Summary.Text className="pb-3">{purchase?.description || ''}</Summary.Text>
            <Summary.Title size='big' style={{ color: purchase.curveColor }}>
              <span>Sugestão de compra: </span>
              <CountUp
                end={purchase?.suggestionPurchase || 0}
                preserveValue
                useEasing
              />
            </Summary.Title>
            <div className="w-full flex-1 flex-col flex mt-2">
              <div className="w-full flex-1 flex flex-wrap gap-x-4">
                <Summary.SubTitle><span className="font-bold">Departamento: </span>{purchase?.department || ''}</Summary.SubTitle>
                <Summary.SubTitle><span className="font-bold">Marca: </span>{purchase?.brand || ''}</Summary.SubTitle>
              </div>
              <div className="w-full flex-1 flex flex-wrap gap-x-4">
                <Summary.SubTitle><span className="font-bold">Média Mensal: </span>{purchase?.monthlyAverage || 0}</Summary.SubTitle>
                <Summary.SubTitle><span className="font-bold">Estoque: </span>{purchase?.stock || 0}</Summary.SubTitle>
              </div>
            </div>
          </Summary.TextContent>
        </Summary.Box>
      ))}
    </Summary.Root>
  )
}
