import React, { useState, useEffect } from 'react'

// API
import { Tabs, Tab, Grid } from '@material-ui/core'
import moment from 'moment'
import API from '../../services/api'

// Base Page
import BasePage from '../basePage'

// Molecules
import {
  RelatorioAgenda,
  RelatorioCliente,
  RelatorioNotas,
  RelatorioPets,
  PackageReport,
  Downloads,
} from '../../components/molecules'

// Material.ui

// moment
import 'moment/locale/pt-br'
import { useLocation } from 'react-router-dom'

moment.locale('pt-br')

// import Relatorio from '../components/organisms/Relatorio'
import { ReportProvider } from '../../providers/report'
import { RoyaltyStatement } from './RoyaltyStatement'
import { PurchaseReport } from './PurchaseReport'

function RelatorioPage() {


  const { search } = useLocation()
  const showCompras = new URLSearchParams(search).get('compras')

  const [value, setValue] = useState('0')
  const handleChangeIndex = (event, value) => setValue(value)

  // CONST RELATORIO AGENDAMENTOS
  const [infoPanelClient, setInfoPanelClient] = useState(null)

  // CONST RELATORIO CLIENTE
  const link = null
  const [reportCustomers, setReportCustomers] = useState(null)
  const [paginationCliente, setPaginationCliente] = useState(null)

  // CONST RELATORIO PETS
  const linkPets = null
  const [reportCustomersPets, setReportCustomersPets] = useState(null)
  const [paginationPets, setPaginationPets] = useState(null)
  const [infoPanelPets, setInfoPanelPets] = useState(null)

  // CONST RELATORIO PETS
  const linkPackageReport = null
  const [reportCustomersPackageReport, setReportCustomersPackageReport] =
    useState(null)
  const [paginationPackageReport, setPaginationPackageReport] = useState(null)
  const [infoPanelPackageReport, setInfoPanelPackageReport] = useState(null)

  const date = new Date()
  const primeiroDia = moment(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format('YYYY-MM-DD')

  // GET RELATORIO CLIENTE
  const getReportsCustomers = async (currentePage = 1) => {
    try {
      const { data } = await API.get(
        `reports/customers?currentePage=${currentePage}&perPage=50`,
      )
      setReportCustomers(data?.results)
      setPaginationCliente(data?.pagination)
      setInfoPanelClient(data?.panel)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  // GET RELATORIO PETS
  const getReportsCustomersPets = async (currentePage = 1) => {
    try {
      const { data } = await API.get(
        `reports/pets?currentePage=${currentePage}&perPage=50`,
      )
      setReportCustomersPets(data?.results)
      setPaginationPets(data?.pagination)
      setInfoPanelPets(data?.panel)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  // GET RELATORIO PACOTES
  const getPackageReport = async (currentePage = 1) => {
    try {
      const { data } = await API.get(
        `/reports/packages?currentePage=${currentePage}&perPage=50`,
      )
      setReportCustomersPackageReport(data?.results)
      setPaginationPackageReport(data?.pagination)
      setInfoPanelPackageReport(data?.panel)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getPackageReport()
    getReportsCustomers()
    getReportsCustomersPets()
  }, [])

  return (
    <ReportProvider>
      <BasePage pageTitle="Relatórios">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeIndex}
          aria-label="disabled tabs example"
          style={{
            boxShadow: '0 6px 20px rgb(0 0 0 / 10%)',
            borderRadius: '7px',
          }}
        >
          <Tab label="AGENDAMENTOS" value="0" />
          <Tab label="CLIENTES" value="1" />
          <Tab label="PETS" value="5" />
          <Tab label="PACOTES" value="2" />
          <Tab label="MINHAS EXPORTAÇÕES" value="3" />
          {showCompras && <Tab label="COMPRAS" value="compras" />}
          {/* <Tab label="NOTAS FISCAIS" value="4" /> */}
          {/* <Tab label="Extrato Royalties" value="royalty-statement" /> */}
        </Tabs>

        {value === "royalty-statement" && (
          <Grid container spacing={3} style={{ flex: 1, height: "100%" }}>
            <Grid item xs style={{ marginTop: "20px" }}>
              <RoyaltyStatement />
            </Grid>
          </Grid>
        )}

        {parseInt(value, 10) === 0 && (
          <RelatorioAgenda primeiroDia={primeiroDia} date={date} />
        )}

        {parseInt(value, 10) === 1 && (
          <RelatorioCliente
            pagination={paginationCliente}
            reportCustomers={reportCustomers}
            link={link}
            setReportCustomers={setReportCustomers}
            getReportsCustomers={getReportsCustomers}
            infoPanel={infoPanelClient}
          />
        )}

        {parseInt(value, 10) === 2 && (
          <PackageReport
            pagination={paginationPackageReport}
            reportCustomers={reportCustomersPackageReport}
            link={linkPackageReport}
            setReportCustomers={setReportCustomersPackageReport}
            getReportsCustomers={getPackageReport}
            infoPanel={infoPanelPackageReport}
          />
        )}

        {parseInt(value, 10) === 3 && <Downloads setValue={setValue} />}

        {parseInt(value, 10) === 5 && (
          <RelatorioPets
            pagination={paginationPets}
            reportCustomers={reportCustomersPets}
            link={linkPets}
            setReportCustomers={setReportCustomersPets}
            getReportsCustomers={getReportsCustomersPets}
            infoPanel={infoPanelPets}
          />
        )}

        {parseInt(value, 10) === 4 && (
          <RelatorioNotas primeiroDia={primeiroDia} date={date} link={link} />
        )}

        {value === "compras" && (
          <Grid container spacing={3} style={{ flex: 1, height: "100%" }}>
            <Grid item xs style={{ marginTop: "20px" }}>
              <PurchaseReport />
            </Grid>
          </Grid>
        )}
      </BasePage>
      {/* <Relatorio /> */}
    </ReportProvider>
  )
}

export default RelatorioPage
