import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import moment from 'moment'
import { Search } from '@material-ui/icons'
import { Controller, useForm } from 'react-hook-form'

import { Grid, TextField } from '@material-ui/core'
import { useFilterPet } from '../../../hooks/FilterPets'
import API from '../../../services/api'

import styles from './styles'

import 'moment/locale/pt-br'
import { useMerchant } from '../../../store/global/useMerchant'

moment.locale('pt-br')

function Filter() {
  const merchant = useMerchant((st) => st.data.merchant);
  const { control, watch } = useForm()
  const { id } = useParams()
  const { setPetList, setFilterList, filterList } = useFilterPet()
  const [department, setDepartment] = useState([])
  const [status, setStatus] = useState([])

  const statusList = [
    'Agendado',
    'Em Andamento',
    'Orçamento Enviado',
    'Cancelado',
  ]

  useEffect(() => {
    setFilterList({ ...filterList, department, status })
  }, [department, status])

  const { grid, filter, textField, title, filterTitle, filterBox, filterItem } =
    styles()

  const date = new Date()
  const primeiroDia = moment(date).format('YYYY-MM-DD')

  const handleClick = async () => {
    const { dateInicio, dateFim } = watch()
    try {
      const { data } = await API.get(
        `customer/${id}/appointments?dateFrom=${dateInicio}&dateTo=${dateFim}`,
      )
      setPetList(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    handleClick()
  }, [])

  return (
    <Grid item xs={12}>
      <h4 className={title}>Feed de Atividades</h4>
      <form>
        <Grid container spacing={3} className={filter}>
          <Grid item xs={5}>
            <Controller
              control={control}
              name="dateInicio"
              defaultValue={primeiroDia}
              as={({ onChange, value }) => (
                <TextField
                  id="dateStart"
                  label="INICIO"
                  fullWidth
                  type="date"
                  value={value}
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              defaultValue={moment(date).add(30, 'days').format('YYYY-MM-DD')}
              name="dateFim"
              as={({ onChange, value }) => (
                <TextField
                  id="date"
                  label="FIM"
                  type="date"
                  value={value}
                  fullWidth
                  className={textField}
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              onClick={() => handleClick()}
              color="primary"
              disabled={false}
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                color: '#ffffff',
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Roboto',
                lineHeight: '13px',
                borderRadius: '6px',
                justifyContent: 'center',
                textDecoration: 'none',
                padding: '5px',
              }}
            >
              <Search />
            </div>
          </Grid>
        </Grid>
      </form>

      <Grid item xs={12} className={grid}>
        <h2 className={filterTitle}>Filtros</h2>
        {!!merchant.hasTokenVarejo && (
          <div className={filterBox}>
            <h3>Departamentos</h3>
            <label className={filterItem}>
              <input
                type="checkbox"
                onClick={() => {
                  const found = department.find((el) => el === 'Banho e Tosa')
                  if (found) {
                    const newDepartment = department.filter(
                      (value) => value !== 'Banho e Tosa',
                    )
                    setDepartment(newDepartment)
                  } else {
                    setDepartment([...department, 'Banho e Tosa'])
                  }
                }}
              />
              Banho e Tosa
            </label>


            <label className={filterItem}>
              <input
                type="checkbox"
                onClick={() => {
                  const found = department.find(
                    (el) => el === 'Clínica Veterinária',
                  )
                  if (found) {
                    const newDepartment = department.filter(
                      (value) => value !== 'Clínica Veterinária',
                    )
                    setDepartment(newDepartment)
                  } else {
                    setDepartment([...department, 'Clínica Veterinária'])
                  }
                }}
              />
              Clinica Veterinária
            </label>
          </div>
        )}
        <div className={filterBox}>
          <h3>Status</h3>

          {statusList.map((item) => (
            <label className={filterItem} key={item}>
              <input
                type="checkbox"
                onClick={() => {
                  const found = status.find((el) => el === item.toUpperCase())
                  if (found) {
                    const newStatus = status.filter(
                      (value) => value !== item.toUpperCase(),
                    )
                    setStatus(newStatus)
                  } else {
                    setStatus([...status, item.toUpperCase()])
                  }
                }}
              />
              {item}
            </label>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default Filter
