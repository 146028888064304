import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

// Material.ui - styles
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Material.ui - core
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
  Grid,
  Button,
  TextField,
  Box,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import Pagination from '@material-ui/lab/Pagination'

// Material.ui - icons
import { MoreVert } from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'

// moment
import moment from 'moment'
import ModalType from '../../atoms/Modal'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'
import 'moment/locale/pt-br'
import { useMerchant } from '../../../store/global/useMerchant'

moment.locale('pt-br')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 300,
    border: '1px solid #ccc',
  },
  pagination: {
    marginTop: '20px',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  statusSelect: {
    '& div': {
      padding: '8px',
    },
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

const ITEM_HEIGHT = 48

function Package({
  packages,
  packageSold,
  stateValue,
  getPackage,
  getPackageSold,
  primeiroDia,
  date,
  pagination,
  setPackageSold,
  getPackageActive,
}) {
  const merchant = useMerchant((st) => st.data.merchant);

  const [value, setValue] = useState('0')
  const history = useHistory()
  const { control, watch } = useForm()
  const handleChangeIndex = (event, value) => setValue(value)

  const isClinica = history.location.pathname.split('/').includes('packages')

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const ordersExport = async (orderId) => {
    try {
      const result = await API.post(`packages/orders/${orderId}/send-to-vo`)
      setOpenModal(result?.data.message)
      result?.status === 201 && getPackageSold()
      setError(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const handleOrder = (id) => {
    setAnchorEl(null)
    setOpen(false)
    ordersExport(id)
  }

  const handleCloseActive = (id) => {
    setAnchorEl(null)
    setOpen(false)
    history.push(`/pacotes/vendas?clinica=${isClinica}`, { id })
  }

  const deletePackage = async (orderId) => {
    try {
      const result = await API.delete(`packages/order/${orderId}`)
      setOpenModal(result?.data.message)
      result?.status === 200 && getPackageSold()
      setError(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleGetPackge = async ({ target }) => {
    getPackage(target.value)
  }

  const handleClick = () => {
    const { dateInicio, dateFim } = watch()
    setPackageSold(null)
    getPackageSold(1, dateInicio, dateFim)
  }

  const handleChangePage = (event, newPage) => {
    setPackageSold(null)
    const { dateInicio, dateFim } = watch()
    getPackageSold(newPage, dateInicio, dateFim)
  }

  const handleActiveCupom = async (active) => {
    await getPackageActive(active)
  }

  const handleUpdateActive = async (active, id) => {
    try {
      const { data } = await API.post(`packages/rule/${id}/status`, {
        active: active !== 'ATIVO',
      })
      setOpenModal(data?.message)
      setError(false)

      setTimeout(() => {
        getPackageActive()
      }, 1500)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  return (
    <BasePage pageTitle="">
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
        aria-label="disabled tabs example"
        style={{
          boxShadow: '0 6px 20px rgb(0 0 0 / 10%)',
          borderRadius: '7px',
        }}
      >
        <Tab label="PACOTES DISPONÍVEIS" value="0" />
        <Tab label="PACOTES VENDIDOS" value="1" />
      </Tabs>

      {parseInt(value, 10) === 0 && (
        <>
          <Grid container spacing={3} className={classes.filter}>
            <Grid item xs={3}>
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  placeholder="Nome"
                  onChange={handleGetPackge}
                  size="small"
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={2}>
              <Controller
                control={control}
                defaultValue="ATIVO"
                name="status"
                as={({ onChange, value }) => (
                  <TextField
                    select
                    fullWidth
                    value={value}
                    label="STATUS"
                    variant="outlined"
                    size="small"
                    className={classes.statusSelect}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  >
                    <MenuItem
                      key="ATIVO"
                      value="ATIVO"
                      onClick={() => handleActiveCupom(true)}
                    >
                      ATIVO
                    </MenuItem>
                    <MenuItem
                      key="INATIVO"
                      value="INATIVO"
                      onClick={() => handleActiveCupom(false)}
                    >
                      INATIVO
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={7} style={{ textAlign: 'right' }}>
              <CustomButton
                onClick={() => stateValue(1)}
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Criar novo pacote
              </CustomButton>
            </Grid>
          </Grid>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">Desconto</TableCell>
                  <TableCell align="center">Preço c/ desconto</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>

              {/* SKELETON */}

              {packages ? (
                <TableBody>
                  {packages?.map((row) => (
                    <TableRow key={row?.promotionName}>
                      <TableCell component="th" scope="row">
                        {row?.promotionName}
                      </TableCell>
                      <TableCell align="center">{row?.discount}</TableCell>
                      <TableCell align="center">
                        {row?.priceWithDiscount?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="center">{row?.status}</TableCell>
                      <TableCell align="center">
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls={row?.id}
                            aria-haspopup="true"
                            onClick={onDecrement(row?.id)}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id={row?.id}
                            anchorEl={anchorEl}
                            keepMounted
                            open={row?.id === open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem>
                              <Link
                                style={{
                                  color: 'rgba(0, 0, 0, 0.87)',
                                  textDecoration: 'none',
                                }}
                                to={`/pacotes/edit/${row?.id}`}
                              >
                                Editar
                              </Link>
                            </MenuItem>
                            {row?.status === 'ATIVO' && (
                              <MenuItem
                                key={row.name}
                                onClick={() => handleCloseActive(row.id)}
                              >
                                Vender
                              </MenuItem>
                            )}
                            <MenuItem
                              key={row.status}
                              onClick={() =>
                                handleUpdateActive(row.status, row.id)
                              }
                            >
                              {row?.status === 'ATIVO' ? 'Inativar' : 'Ativar'}
                            </MenuItem>
                          </Menu>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
      {parseInt(value, 10) === 1 && (
        <>
          {primeiroDia && (
            <form>
              <Grid container spacing={3} className={classes.filter}>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="dateInicio"
                    defaultValue={primeiroDia}
                    as={({ onChange, value }) => (
                      <TextField
                        id="dateStart"
                        label="INICIO"
                        type="date"
                        value={value}
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    control={control}
                    defaultValue={moment(date).format('YYYY-MM-DD')}
                    name="dateFim"
                    as={({ onChange, value }) => (
                      <TextField
                        id="date"
                        label="FIM"
                        type="date"
                        value={value}
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={7}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ width: '100%' }}
                  >
                    <Box justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={() => handleClick()}
                        color="primary"
                        disabled={!packageSold}
                        style={{
                          background: '#CCD219',
                          fontSize: '14px',
                          textTransform: 'capitalize',
                          fontWeight: '700',
                          width: '138px',
                          marginRight: '20px',
                        }}
                      >
                        Filtrar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell align="left">Pet</TableCell>
                  <TableCell align="left">Pacote</TableCell>
                  <TableCell align="left">Vendedor</TableCell>
                  <TableCell align="center">Preço c/ desconto</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Data</TableCell>
                  {!!merchant.hasTokenVarejo && (
                    <TableCell align="center" />
                  )}
                </TableRow>
              </TableHead>
              {packageSold ? (
                <TableBody>
                  {packageSold?.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.customerName}
                      </TableCell>
                      <TableCell align="left">{row.pet}</TableCell>
                      <TableCell align="left">{row.package}</TableCell>
                      <TableCell align="left">{row.seller}</TableCell>
                      <TableCell align="center">
                        {row.totalPrice.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      {!!merchant.hasTokenVarejo && (
                        <TableCell align="center">
                          <>
                            <IconButton
                              aria-label="more"
                              aria-controls={row.packageOrderId}
                              aria-haspopup="true"
                              onClick={onDecrement(row.packageOrderId)}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id={row.packageOrderId}
                              anchorEl={anchorEl}
                              keepMounted
                              open={row.packageOrderId === open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItem
                                key="ativo"
                                selected="ativo"
                                disabled={row.status === 'INTEGRADO'}
                                onClick={() => handleOrder(row.packageOrderId)}
                              >
                                Enviar para o ERP
                              </MenuItem>

                              <MenuItem
                                key="ativo"
                                selected="ativo"
                                onClick={() => deletePackage(row.packageOrderId)}
                              >
                                Deletar pacote
                              </MenuItem>
                            </Menu>
                          </>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ justifyContent: 'center' }}
            className={classes.pagination}
          >
            {pagination && (
              <Pagination
                count={pagination.total_pages}
                variant="outlined"
                onChange={handleChangePage}
              />
            )}
          </Grid>
        </>
      )}
    </BasePage>
  )
}

export default Package
