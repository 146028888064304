import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  isWaiting: false,
  highlighteds: 3,
  purchaseReport: [],
  purchaseHighlightReport: [],
  filter: {
    dateFrom: null,
    dateTo: null,
    department: '*',
    description: '',
    brand: '',
  },
  sort: {
    field: '*',
    direction: "-1",
  },
  pagination: {
    page: 0,
    totalPages: 0,
    perPage: 10,
    totalDocuments: 0,
  },
};

export const usePurchaseReports = create((set) => ({
  data: defaultData,
  actions: {
    setPurchaseReport: (purchaseReports, pagination) =>
      set((state) =>
        produce(state, (draft) => {
          const page =
            typeof pagination === "number" ? pagination : pagination.page;

          draft.data.purchaseReport[page] = purchaseReports;
          draft.data.pagination = pagination;

          draft.data.isLoading = false;
          draft.data.isWaiting = false;
        })
      ),
    setPurchaseHighlightReport: (purchaseHighlightReport, force = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!!state.data.purchaseHighlightReport.length && !force) return
          draft.data.purchaseHighlightReport = purchaseHighlightReport
        })
      ),
    setFilter: (filterProps) =>
      set((state) =>
        produce(state, (draft) => {
          const filter = state.data.filter;
          draft.data.filter = { ...filter, ...filterProps };
        })
      ),
    setSort: (sortProps) =>
      set((state) =>
        produce(state, (draft) => {
          const sort = state.data.sort;
          draft.data.sort = { ...sort, ...sortProps };
        })
      ),
    setPaginationPage: (page) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = page;
        })
      ),
    setPaginationPerPage: (perPage) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = 0;
          draft.data.pagination.perPage = perPage;
          draft.data.purchaseReport = [];
        })
      ),

    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
          draft.data.isWaiting = status;
        })
      ),

    setWaiting: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isWaiting = status;
        })
      ),

    clearPurchaseReport: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.purchaseReport = [];
        })
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
  },
}));
