import { toast } from "react-toastify";

import API from "../../api";
import moment from "moment";

export default class SubscriptionIndicatorModel {
  getIndicator = async (indicator) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/indicadores?indicador=${indicator}`);
      return data.resultado
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  };
  getBi = async ({ startDate, endDate, storeId }) => {

    const start = moment(startDate || new Date()).format('YYYY-MM-DD')
    const end = moment(endDate || new Date()).format('YYYY-MM-DD')

    try {
      const { data } = await API.get(`/ordens-servico/estatisticas?startDate=${start}&endDate=${end}&storeId=${storeId}`);
      return data
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  };
}
