import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// Modal
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  Typography,
  Divider,
  Button,
} from '@material-ui/core'
import ModalType from '../../atoms/Modal'

// Serviços

// Material ui - style

// Serviços
import { getCategories, newCupom } from '../../../services/cupom'

// Material ui
import BasePage from '../../../pages/basePage'
import { useMerchant } from '../../../store/global/useMerchant'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 700,
    border: '1px solid #ccd219',
  },
  tableContainer: {
    marginTop: '20px',
  },
  pagination: {
    marginTop: '20px',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #ccd219',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    '& h6': {
      marginBottom: '-8px',
      color: '#000',
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
    },
  },
  displayCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    margin: '35px -5px',
  },
  check: {
    display: 'inline',
  },
  textField: {
    '& input': {
      padding: '20px',
    },
  },
  title: {
    fontSize: '20px',
    padding: '11px 0',
  },
  description: {
    fontSize: '14px',
    padding: '15px 0 5px',
  },
}))

const week = [
  { name: 'Domingo', value: 'sunday' },
  { name: 'Segunda', value: 'monday' },
  { name: 'Terça', value: 'tuesday' },
  { name: 'Quarta', value: 'wednesday' },
  { name: 'Quinta', value: 'thursday' },
  { name: 'Sexta', value: 'friday' },
  { name: 'Sábado', value: 'saturday' },
]

const channels = ['APP', 'LOJA']

function Cupom() {
  const history = useHistory()
  const classes = useStyles()
  const merchant = useMerchant((st) => st.data.merchant);

  // Get categorias
  const [categories, setCategories] = React.useState([])

  const defaultIds = categories?.map((item) => item.id)
  const weekIds = week?.map((item) => item.value)
  const channel = channels?.map((item) => item)

  const { register, control, handleSubmit, getValues } = useForm({
    defaultValues: {
      item_ids: defaultIds,
      week_id: weekIds,
      channels: channel,
    },
  })

  // state
  const [activeSwitch, setActiveSwitch] = React.useState(true)

  // state check
  const [usesPerCustomer, setUsesPerCustomer] = React.useState(false)
  const [checkStart, setCheckStart] = React.useState(false)
  const [checkFinish, setCheckFinish] = React.useState(false)
  const [cpf, setCpf] = React.useState(false)
  const [firstPurchase, setFirstPurchase] = React.useState(false)
  const [selectedCheckList, setSelectedCheckList] = React.useState(weekIds)

  // Modal
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)

  const closeModal = () => {
    setOpenModal(false)
  }

  // Submit
  const onSubmit = async (data) => {
    const payload = {
      promotionName: data?.promotionName,
      code: data?.code,
      cpf: cpf ? [data?.cpf] : [], // array de cpf, mandar como string o cpf
      categories: data?.item_ids, // passar o id das categorias
      daysOfWeek: selectedCheckList, // enviar os dias em inglês
      usesPerCustomer: usesPerCustomer ? data?.usesPerCustomer : null, // number
      firstPurchase, // true or falser
      validFromDate: checkStart ? data?.dateStart : null, // String em padrão americano "aaaa/mm/dd"
      validToDate: checkFinish ? data?.dateFinish : null, // String em padrão americano "aaaa/mm/dd"
      discountType: data?.discountType, // "by_fixed_value" or "by_percent"
      discountAmount: data?.discount, // number
      channels: data?.channels,
      active: activeSwitch,
    }
    const result = await newCupom(payload, setError, setOpenModal)

    if (result?.message) {
      setOpenModal(result?.message)
      setTimeout(() => {
        history.push('/cupom')
      }, 1500)
    }
  }

  const handleChangeSwitch = () => {
    setActiveSwitch(!activeSwitch)
  }

  const handleCheck = (checkedId) => {
    const { item_ids: ids } = getValues()
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id) => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  const handleChannel = (checked) => {
    const { channels: ids } = getValues()
    const newIds = ids?.includes(checked)
      ? ids?.filter((id) => id !== checked)
      : [...(ids ?? []), checked]
    return newIds
  }

  React.useEffect(() => {
    const intiCategories = async () => {
      const value = await getCategories()
      setCategories(value?.results)
    }
    intiCategories()
  }, [])

  return (
    <BasePage pageTitle="Novo Cupom">
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <form className={classes.filter} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="promotionName"
              label="NOME DA CAMPANHA"
              margin="normal"
              variant="outlined"
              placeholder="Cupom 1º compra"
              name="promotionName"
              fullWidth
              required
              inputRef={register}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="code"
              label="CÓDIGO DO CUPOM"
              placeholder="primeirobanho"
              margin="normal"
              variant="outlined"
              name="code"
              inputRef={register}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              id="discount"
              label="VALOR DO DESCONTO"
              placeholder="Valor do Desconto"
              name="discount"
              variant="outlined"
              type="number"
              fullWidth
              required
              inputRef={register}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              defaultValue="by_percent"
              as={
                <TextField
                  id="outlined-basic"
                  label="TIPO DE DESCONTO"
                  select
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem key="by_percent" value="by_percent">
                    Porcentagem (%)
                  </MenuItem>
                  <MenuItem key="by_fixed_value" value="by_fixed_value">
                    R$ (valor fixo)
                  </MenuItem>
                </TextField>
              }
              name="discountType"
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="active"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={activeSwitch}
                        onChange={handleChangeSwitch}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Ativo"
                  />
                </FormGroup>
              )}
              control={control}
            />
          </Grid>
        </Grid>

        {/* Titulo cliente e restição */}
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          className={classes.title}
        >
          Restrição de uso
        </Typography>

        <Typography
          gutterBottom
          variant="h5"
          component="h4"
          className={classes.title}
        >
          Cliente
        </Typography>
        {/* Titulo cliente e restição */}

        <Grid container spacing={3}>
          <Grid item xs={4} className={classes.displayCheck}>
            <Controller
              name="usesPerCustomer"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setUsesPerCustomer(!usesPerCustomer)}
                        defaultChecked={usesPerCustomer}
                      />
                    }
                    key="usesPerCustomer"
                  />
                </FormGroup>
              )}
              control={control}
            />

            <TextField
              id="usesPerCustomer"
              label="USOS POR CLIENTE"
              placeholder="Nº vezes que o cliente pode usar"
              name="usesPerCustomer"
              variant="outlined"
              inputRef={register}
              disabled={!usesPerCustomer}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4} className={classes.displayCheck}>
            <Controller
              name="cpf"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setCpf(!cpf)}
                        defaultChecked={cpf}
                      />
                    }
                    key="cpf"
                  />
                </FormGroup>
              )}
              control={control}
            />

            <TextField
              id="cpf"
              label="CPF"
              placeholder="999.999.999-99"
              name="cpf"
              variant="outlined"
              inputRef={register}
              disabled={!cpf}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={3} className={classes.displayCheck}>
            <Controller
              name="firstPurchase"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={firstPurchase}
                        onChange={() => setFirstPurchase(!firstPurchase)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Somente 1º compra"
                  />
                </FormGroup>
              )}
              control={control}
            />
          </Grid>
        </Grid>

        {/* DIVIDER */}
        <Divider className={classes.divider} />
        {/* DIVIDER */}

        <Typography
          gutterBottom
          variant="h5"
          component="h4"
          className={classes.title}
        >
          Data
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.displayCheck}>
            <Controller
              name="checkStart"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setCheckStart(!checkStart)}
                        defaultChecked={checkStart}
                      />
                    }
                    key="checkStart"
                  />
                </FormGroup>
              )}
              control={control}
            />

            <Controller
              control={control}
              name="dateStart"
              as={({ onChange, value }) => (
                <TextField
                  id="dateStart"
                  label="INICIO"
                  type="date"
                  value={value}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  disabled={!checkStart}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.displayCheck}>
            <Controller
              name="checkFinish"
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setCheckFinish(!checkFinish)}
                        defaultChecked={checkFinish}
                      />
                    }
                    key="checkFinish"
                  />
                </FormGroup>
              )}
              control={control}
            />

            <Controller
              control={control}
              name="dateFinish"
              as={({ onChange, value }) => (
                <TextField
                  id="dateFinish"
                  label="FIM"
                  type="date"
                  value={value}
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  disabled={!checkFinish}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* INICIO CHECK BOX DIA DA SEMANA */}
        <Typography component="p" className={classes.description}>
          Dias da semana que a campanha é válida.
        </Typography>

        <Grid container spacing={3}>
          {weekIds && (
            <Grid item xs={6}>
              <Controller
                name="week_id"
                defaultValue={weekIds}
                render={() => (
                  <FormGroup className={classes.check}>
                    {week.map((item) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            disableRipple
                            value={item.value}
                            defaultValue={weekIds}
                            defaultChecked={weekIds?.find(
                              (id) => id === item.value,
                            )}
                            onChange={(event, checked) => {
                              const currentValues = !checked
                                ? selectedCheckList.filter(
                                  (item) => item !== event.target.value,
                                )
                                : [...selectedCheckList, event.target.value]
                              setSelectedCheckList(currentValues)
                            }}
                          />
                        }
                        key={item.value}
                        label={item.name}
                      />
                    ))}
                  </FormGroup>
                )}
                control={control}
              />
            </Grid>
          )}
        </Grid>

        {/* DIVIDER */}
        <Divider className={classes.divider} />
        {/* DIVIDER */}

        {/* INICIO CHECK BOX SERVIÇOS DISPONIVEIS */}
        <Typography
          gutterBottom
          variant="h5"
          component="h4"
          className={classes.title}
        >
          Serviços
        </Typography>

        <Typography component="p" className={classes.description}>
          Categorias válidas.
        </Typography>

        <Grid container spacing={3}>
          {defaultIds.length > 0 && (
            <Grid item xs={6}>
              <Controller
                name="item_ids"
                defaultValue={defaultIds}
                render={(props) => (
                  <FormGroup className={classes.check}>
                    {categories?.map((item) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue={defaultIds}
                            onChange={() =>
                              props.onChange(handleCheck(item.id))
                            }
                            defaultChecked={defaultIds?.find(
                              (id) => id === item.id,
                            )}
                          />
                        }
                        key={item.id}
                        label={item.name}
                      />
                    ))}
                  </FormGroup>
                )}
                control={control}
              />
            </Grid>
          )}
        </Grid>

        {/* DIVIDER */}
        <Divider className={classes.divider} />
        {/* DIVIDER */}
        {!!merchant.hasTokenVarejo && (
          <>
            <Typography
              gutterBottom
              variant="h5"
              component="h4"
              className={classes.title}
            >
              Canais
            </Typography>

            <Grid container spacing={3}>
              {channel && (
                <Grid item xs={6}>
                  <Controller
                    name="channels"
                    defaultValue={channel}
                    render={(props) => (
                      <FormGroup className={classes.check}>
                        {channels.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultValue={item}
                                defaultChecked={item}
                                onChange={() => props.onChange(handleChannel(item))}
                              />
                            }
                            key={item}
                            label={item}
                          />
                        ))}
                      </FormGroup>
                    )}
                    control={control}
                  />
                </Grid>
              )}
            </Grid>

            {/* DIVIDER */}
            <Divider className={classes.divider} />
            {/* DIVIDER */}
          </>
        )}

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push('/cupom')}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </form>
    </BasePage>
  )
}

export default Cupom
