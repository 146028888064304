import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { CalendarToday } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'

// moment
import moment from 'moment'
import { useFilterPet } from '../../../hooks/FilterPets'
import 'moment/locale/pt-br'

import styles from './styles'

moment.locale('pt-br')

function Feed() {
  const { petList, filterList } = useFilterPet([])
  const [filtredPet, setFiltredPet] = useState(petList)

  const statusColors = {
    AGENDADO: '#00A1A4',
    'EM ANDAMENTO': '#C64D21',
    'ORÇAMENTO ENVIADO': '#59602C',
    CANCELADO: '#A2A2A0',
  }
  useEffect(() => {


    console.log('petList', filterList.department.length)
    if (
      filterList.department.length ||
      filterList.status.length ||
      !!filterList.pet
    ) {
      const findDepartment = filterList.department.length
        ? petList.filter(({ department }) =>
          filterList.department.includes(department),
        )
        : petList
      const findStatus = filterList.status.length
        ? findDepartment.filter(({ status }) =>
          filterList.status.includes(status),
        )
        : findDepartment
      const findName = filterList.pet
        ? findStatus.filter(({ petName }) => filterList.pet === petName)
        : findStatus
      setFiltredPet(findName)
    } else {
      setFiltredPet(petList)
    }
  }, [filterList, petList])

  const {
    avatar,
    grid,
    item,
    link,
    badge,
    infoList,
    info,
    infoTitle,
    badgeContent,
    pet,
    petName,
    petImage,
  } = styles()

  return (
    <Grid item xs={12} className={grid}>
      {filtredPet &&
        filtredPet.map(
          ({
            appointmentId,
            department,
            date,
            hour,
            id,
            petImg,
            petName: name,
            services,
            status,
            employeeId,
          }) => (
            <Grid item xs={12} className={item} key={id} classes={item}>
              <Link
                to={{
                  pathname:
                    department === 'Clínica Veterinária'
                      ? `/clinica/check-in/order/${appointmentId}?employeeId=${employeeId}`
                      : `/banho-tosa/check-in/order/${appointmentId}?employeeId=${employeeId}`,
                }}
                target="_blank"
                className={link}
              >
                <span
                  className={badge}
                  style={{ background: statusColors[status] }}
                >
                  <dd className={badgeContent}>{status}</dd>
                  <dd className={badgeContent}>
                    <CalendarToday />
                    {moment(date).format('DD/MM/YYYY')} às
                    {hour}
                  </dd>
                </span>
                <div className={pet}>
                  <div className={avatar}>
                    {petImg && (
                      <img src={petImg} alt={name} className={petImage} />
                    )}
                    {!petImg && name.charAt(0)}
                  </div>
                  <p className={petName}>{name}</p>
                </div>
                <div className={infoList}>
                  <p className={infoTitle}>Departamento</p>
                  <p className={info}>{department}</p>
                  <p className={infoTitle}>Serviços</p>
                  <p className={info}>{services}</p>
                </div>
              </Link>
            </Grid>
          ),
        )}
    </Grid>
  )
}

export default Feed
