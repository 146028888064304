const lightTheme = {
  animation: {
    delay: {
      lazy: "1s",
      slow: "500ms",
      normal: "250ms",
      fast: "125ms",
    },
  },

  colors: {
    background: "#fafafa",
    black: "#000000",
    white: "#ffffff",
    overlay: "#00000082",
    error: "#D22F19",
    success: "#65CF72",
    warning: "#f8cd04",
    finished: "#59602c",
    text: "#d05d02",
    primary: "#d05d02",
    secondary: "#F2F2F2",
    scheduleSteps: {
      scheduled: "#00A1A4",
      preScheduled: "#C72296",
      scheduledVaccine: "#91B3F4",
      checkIn: "#C64D21",
      checkout: "#59602C",
      blocked: "#7A7373",
      newSchedule: "transparent",
      waitingStart: "#C64D21",
      started: "#C64D21",
      finished: "#C64D21",
      budgetSent: "#59602C",
      paid: "#8d00ff",
    },
    gray: {
      50: "#E0E0E0",
      100: "#E0E0E0",
      200: "#CCCCCC",
      300: "#B8B5B5",
      400: "#A39E9E",
      500: "#8F8888",
      600: "#7A7373",
      700: "#665E5E",
      800: "#524A4A",
      900: "#3D3636",
    },
    highlights: {
      50: "#FEFFEA",
      100: "#FDFFBB",
      200: "#FBFF8C",
      300: "#FAFF5E",
      400: "#EEF42D",
      500: "#CCD219",
      600: "#ABB00A",
      700: "#898E00",
      800: "#696C00",
      900: "#484A00",
    },
    scale: {
      50: "#FFF2E8",
      100: "#FFDBBE",
      200: "#FFC495",
      300: "#FFAD6C",
      400: "#FF9542",
      500: "#F97B18",
      600: "#D05D02",
      700: "#A74A00",
      800: "#7E3800",
      900: "#562600",
    },
    ranking: {
      gold: "linear-gradient(135deg, #ffd700, #ffd300, #ffd700);",
      silver: "linear-gradient(135deg, #c0c0c0, #f0f0f0, #c0c0c0)",
      bronze: "linear-gradient(135deg, #a97136, #ccaa00, #a97136)",
    },
  },
};

const darkTheme = {
  animation: {
    delay: {
      lazy: "1s",
      slow: "500ms",
      normal: "250ms",
      fast: "125ms",
    },
  },
  colors: {
    background: "#fafafa",
    black: "#000000",
    white: "#ffffff",
    overlay: "#00000082",
    error: "#D22F19",
    success: "#65CF72",
    warning: "#f8cd04",
    finished: "#59602c",
    text: "#263E6C",
    primary: "#263E6C",
    secondary: "#F2F2F2",
    scheduleSteps: {
      gostScheduled: "#00a1a469",
      scheduledVaccine: "#91B3F4",
      blocked: "#cccccc",
      newSchedule: "#7092D2",
      preScheduled: "#C72296",
      scheduled: "#00A1A4",
      waitingStart: "#E3A74B",
      checkIn: "#C64D21",
      finished: "#34A000",
      checkout: "#59602C",
      paid: "#cccccc",
    },
    scale: {
      50: "#F4F8FF",
      100: "#DDE9FF",
      200: "#C6D9FF",
      300: "#AFCAFF",
      400: "#91B3F4",
      500: "#7092D2",
      600: "#5373B0",
      700: "#3B578E",
      800: "#263E6C",
      900: "#16284A",
    },
    highlights: {
      50: "#FEFFEA",
      100: "#FDFFBB",
      200: "#FBFF8C",
      300: "#FAFF5E",
      400: "#EEF42D",
      500: "#CCD219",
      600: "#ABB00A",
      700: "#898E00",
      800: "#696C00",
      900: "#484A00",
    },
    gray: {
      50: "#E0E0E0",
      100: "#E0E0E0",
      200: "#CCCCCC",
      300: "#B8B5B5",
      400: "#A39E9E",
      500: "#8F8888",
      600: "#7A7373",
      700: "#665E5E",
      800: "#524A4A",
      900: "#3D3636",
    },
  },
};

const tempTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,

    highlights: {
      50: "#E0E0E0",
      100: "#E0E0E0",
      200: "#CCCCCC",
      300: "#B8B5B5",
      400: "#A39E9E",
      500: "#8F8888",
      600: "#7A7373",
      700: "#665E5E",
      800: "#524A4A",
      900: "#3D3636",
    },
    scale: {
      50: "#E0E0E0",
      100: "#E0E0E0",
      200: "#CCCCCC",
      300: "#B8B5B5",
      400: "#A39E9E",
      500: "#8F8888",
      600: "#7A7373",
      700: "#665E5E",
      800: "#524A4A",
      900: "#3D3636",
    },
  },
};
export { lightTheme, darkTheme, tempTheme };
