import RoyaltyStatementControllerClass from "./RoyaltyStatementController";
import PurchaseReportControllerClass from "./PurchaseReportController";

let CRoyaltyStatement = null;
let CPurchaseReport = null;

export function RoyaltyStatementController() {
  if (CRoyaltyStatement) {
    return CRoyaltyStatement;
  }

  CRoyaltyStatement = new RoyaltyStatementControllerClass();
  return CRoyaltyStatement;
}

export function PurchaseReportController() {
  if (CPurchaseReport) {
    return CPurchaseReport;
  }

  CPurchaseReport = new PurchaseReportControllerClass();
  return CPurchaseReport;
}
