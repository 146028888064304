import React from "react";

import { PurchaseReportFilter } from "./PurchaseReportFilter";
import { PurchaseReportTable } from "./PurchaseReportTable";
import { PurchaseReportHighlight } from "./PurchaseReportHighlight";

import { Container } from "./styles";

export function PurchaseReport() {
  return (
    <Container className="space-y-5">
      <PurchaseReportFilter />
      <PurchaseReportHighlight />
      <PurchaseReportTable />
    </Container>
  );
}
