import React from "react";
import moment from "moment";
import { FileDown, Filter as FilterIcon } from "lucide-react";

import { Input } from "../../../../components/ui/Form/Input";
import { Filter } from "../../../../components/ui/Filter";
import { PurchaseReportController } from "../../../../services/controllers/reports";
import { usePurchaseReports } from "../../../../store/reports/usePurchaseReport";
import { Select } from "../../../../components/ui/Form/Select";
import { ChevronsUp, ChevronsDown } from "lucide-react";
import { Search } from "lucide-react";

export function PurchaseReportFilter() {
  const CPurchaseReport = PurchaseReportController();
  const filter = usePurchaseReports((st) => st.data.filter);
  const sort = usePurchaseReports((st) => st.data.sort);
  const isLoading = usePurchaseReports((st) => st.data.isLoading);

  return (
    <Filter.Root onSubmit={CPurchaseReport.filterPurchases}>
      <Filter.Container>
        <div className="w-full flex gap-2 flex-wrap">
          <Input.Root
            pickDate
            label="Início"
            style={{ maxWidth: "300px" }}
            value={moment(filter.dateFrom).format("DD/MM/YYYY")}
            onChange={(dateFrom) => {
              CPurchaseReport.setFilter({ dateFrom });
            }}
          />
          <Input.Root
            pickDate
            label="Fim"
            style={{ maxWidth: "300px" }}
            value={moment(filter.dateTo).format("DD/MM/YYYY")}
            onChange={(dateTo) => {
              CPurchaseReport.setFilter({ dateTo });
            }}
          />

          <div className="ml-auto">
            <Filter.Actions>
              <Filter.Action isLoading={isLoading}>
                <Filter.ActionIcon icon={Search} isLoading={isLoading} />
                Buscar
              </Filter.Action>
            </Filter.Actions>
          </div>
        </div>

        <div className="w-full flex gap-2 flex-wrap">
          <div className="flex gap-2 max-w-[800px] flex-1 w-full flex-wrap">
            <Input.Root
              label="Produto"
              style={{ flex: 1, maxWidth: "300px", minWidth: "160px" }}
              defaultValue={filter.description}
              onChange={(description) => {
                CPurchaseReport.setFilter({ description });
              }}
            />
            <Input.Root
              label="Marca"
              style={{ maxWidth: "300px", minWidth: "160px", flex: 1 }}
              defaultValue={filter.brand}
              onChange={(brand) => {
                CPurchaseReport.setFilter({ brand });
              }}
            />
            <Select.Root
              label="Departamento"
              style={{ maxWidth: "300px", minWidth: "160px" }}
              defaultValue={filter.department}
              onChange={(department) => {
                CPurchaseReport.setFilter({ department });
              }}
            >
              <Select.Option value={"*"}>Todos</Select.Option>
              <Select.Option value={"USO E CONSUMO"}>Uso e consumo</Select.Option>
              <Select.Option value={"CASA E JARDIM"}>Casa e jardim</Select.Option>
              <Select.Option value={"ACESSORIOS"}>Acessórios</Select.Option>
              <Select.Option value={"OUTROS ALIMENTOS"}>Outros alimentos</Select.Option>
              <Select.Option value={"ALIMENTOS COMPLETOS"}>Alimentos completos</Select.Option>
              <Select.Option value={"MEDICAMENTOS"}>Medicamentos</Select.Option>
              <Select.Option value={"HIGIENE E LIMPEZA"}>Higiene e limpeza</Select.Option>
              <Select.Option value={"SERES VIVOS"}>Seres vivos</Select.Option>
            </Select.Root>
          </div>
          <div className="ml-auto flex gap-2 min-w-fit ">
            <Select.Root
              label="Ordem"
              style={{ width: "230px" }}
              value={sort.field}
              onChange={(field) => {
                CPurchaseReport.setSort({ field });
              }}
            >
              <Select.Option value={"*"}>Padrão</Select.Option>
              <Select.Option value={"suggestion-purchase"}>Sugestão de compra</Select.Option>
              <Select.Option value={"abc"}>Curva ABC</Select.Option>
              <Select.Option value={"invoicing"}>Faturamento <small className="text-zinc-400">Curva ABC</small></Select.Option>
              <Select.Option value={"sale"}>Venda <small className="text-zinc-400">Curva ABC</small></Select.Option>
            </Select.Root>
            <Select.Root
              style={{ maxWidth: "80px" }}
              value={sort.direction}
              onChange={(direction) => {
                CPurchaseReport.setSort({ direction });
              }}

            >
              <Select.Option value={"1"}><ChevronsUp /></Select.Option>
              <Select.Option value={"-1"}><ChevronsDown /></Select.Option>
            </Select.Root>
          </div>
        </div>
      </Filter.Container>
    </Filter.Root>
  );
}
