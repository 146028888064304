import API from './api'

export const exportToPDV = async (id) => {
  try {
    return await API.post(`service-order/appointment/${id}/send-to-vo`)
  } catch (error) {
    return error
  }
}

export const payServices = async (id) => {
  try {
    return await API.post(`service-order/appointment/${id}/pay`)
  } catch (error) {
    return error
  }
}
