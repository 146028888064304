import React, { useState, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../../../providers/auth";
import { useThemes } from "../../../providers/theme";

// svg banho e tosa
import logo from "../../../assets/logo512.png";
import logoDra from "../../../assets/draMei.png";
import { ReactComponent as CalendarioIcon } from "../../../assets/svg/calendario.svg";
import { ReactComponent as ClienteIcon } from "../../../assets/svg/cliente.svg";
import { ReactComponent as VendasIcon } from "../../../assets/svg/vendas.svg";
import { ReactComponent as RelatorioIcon } from "../../../assets/svg/relatorio.svg";
import { ReactComponent as ConfiguracaoIcon } from "../../../assets/svg/configuracao.svg";

// svg clinica
import { ReactComponent as CalendarioIconClinica } from "../../../assets/svg/clinica/calendario.svg";
import { ReactComponent as ClienteIconClinica } from "../../../assets/svg/clinica/cliente.svg";
import { ReactComponent as VendasIconClinica } from "../../../assets/svg/clinica/vendas.svg";
import { ReactComponent as VacinasIconClinica } from "../../../assets/svg/clinica/vacinas.svg";
import { ReactComponent as RelatorioIconClinica } from "../../../assets/svg/clinica/relatorio.svg";
import { ReactComponent as ConfiguracaoIconClinica } from "../../../assets/svg/clinica/configuracao.svg";

import { Sidebar } from "./styles";

import "./styles.css";
import { useMerchant } from "../../../store/global/useMerchant";

function Menu() {
  const [closeMenu, setCloseMenu] = useState(true);
  const { modules } = useContext(AuthContext);
  const { toggleTheme, theme } = useThemes();
  const merchant = useMerchant((st) => st.data.merchant);

  const history = useHistory();

  const { pathname } = useLocation();

  function toggleSubMenu(event) {
    const arrowParent = event.target.parentElement.parentElement;
    arrowParent.classList.toggle("showMenu");
  }

  const toggleThemeMenu = (themeValue) => {
    toggleTheme(themeValue);
    if (pathname !== "/agenda") {
      history.push("/agenda");
    }
  };

  return (
    <Sidebar closeMenu={closeMenu}>
      <div className={`sidebar ${closeMenu && "close"}`}>
        <div className="logo-details">
          {theme === "petland" ? (
            <img src={logo} alt="Petland" title="petland" />
          ) : (
            <img src={logoDra} alt="Dra.Mei" title="Dra.Mei" />
          )}
          <select
            className="logo_name"
            value={theme}
            onChange={(e) => toggleThemeMenu(e.target.value)}
          >
            {modules?.bath?.schedule && (
              <option value="petland">Petland</option>
            )}
            {modules?.clinic?.schedule && (
              <option value="draMei">Dra.Mei</option>
            )}
          </select>
        </div>
        {theme === "petland" ? (
          <ul className="nav-links">
            <li>
              {/* "/agenda" */}
              <Link to="/agenda">
                <CalendarioIcon />
                <span className="link_name">Agenda</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <Link className="link_name" to="/agenda">
                    Agenda
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/clientes">
                <ClienteIcon />
                <span className="link_name">Clientes</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <Link className="link_name" to="/clientes">
                    Clientes
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <VendasIcon />
                  <span className="link_name">Vendas</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Vendas</span>
                </li>

                <li>
                  <Link to="/crm">CRM</Link>
                </li>
                {!merchant.affiliate && (
                  <li>
                    <Link to="/assinaturas">Assinatura</Link>
                  </li>
                )}
                <li>
                  <Link to="/pacotes">Pacotes</Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <RelatorioIcon />
                  <span className="link_name">Relatórios</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Relatórios</span>
                </li>
                <li>
                  <Link to="/relatorio">Relatórios</Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <ConfiguracaoIcon />
                  <span className="link_name">Configurações</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Configurações</span>
                </li>
                <li>
                  <Link to="/servicos">Serviços</Link>
                </li>
                <li>
                  <Link to="/profissionais">Profissionais</Link>
                </li>
                <li>
                  <Link to="/cupom">Cupons</Link>
                </li>
                <li>
                  <Link to="/configuracoes">Informações de Loja</Link>
                </li>
              </ul>
            </li>
          </ul>
        ) : (
          <ul className="nav-links">
            <li>
              {/* /clinica */}
              <Link to="/agenda">
                <CalendarioIconClinica />
                <span className="link_name">Agenda</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <Link className="link_name" to="/agenda">
                    Agenda
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/clientes">
                <ClienteIconClinica />
                <span className="link_name">Clientes</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <Link className="link_name" to="/clientes">
                    Clientes
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <VendasIconClinica />
                  <span className="link_name">Vendas</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Vendas</span>
                </li>
                <li>
                  <Link to="/">Assinaturas</Link>
                </li>
                <li>
                  <Link to="/packages">Pacotes</Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <VacinasIconClinica />
                  <span className="link_name">Vacinas</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Vacinas</span>
                </li>
                <li>
                  <Link to="/protocolos">Protocolos</Link>
                </li>
                <li>
                  <Link to="/clinica/relatorios/vacinacoes">
                    Vacinas Programadas
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <RelatorioIconClinica />
                  <span className="link_name">Relatórios</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Relatórios</span>
                </li>
                <li>
                  <Link to="/relatorio">Relatórios</Link>
                </li>
                <li>
                  <Link to="/">Avaliações</Link>
                </li>
              </ul>
            </li>

            <li>
              <div className="iocn-link">
                <span className="link_SubMenu">
                  <ConfiguracaoIconClinica />
                  <span className="link_name">Configurações</span>
                </span>
                <i
                  className="bx bxs-chevron-down arrow"
                  onClick={toggleSubMenu}
                />
              </div>
              <ul className="sub-menu">
                <li>
                  <span className="link_name">Configurações</span>
                </li>
                <li>
                  <Link to="/servicos">Serviços</Link>
                </li>
                <li>
                  <Link to="/profissionais">Profissionais</Link>
                </li>
                <li>
                  <Link to="/configuracoes">Informações da Loja</Link>
                </li>
              </ul>
            </li>
          </ul>
        )}

        <section className="home-section">
          <div
            className="home-content"
            onClick={() => setCloseMenu(!closeMenu)}
          >
            {closeMenu ? (
              <i className="bx bx-chevron-right" />
            ) : (
              <i className="bx bx-chevron-left" />
            )}
          </div>
        </section>
      </div>
    </Sidebar>
  );
}

export default Menu;
