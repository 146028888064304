import _ from "lodash";
import { SubscriptionIndicatorModel } from "../../models/subscription";
import { useIndicators } from "../../../store/subscription/useIndicators";

export default class SubscriptionIndicatorController {

  setPotential = useIndicators.getState().actions.setPotential
  setSubscribers = useIndicators.getState().actions.setSubscribers
  setInvoicing = useIndicators.getState().actions.setInvoicing
  setCancellations = useIndicators.getState().actions.setCancellations
  setMargin = useIndicators.getState().actions.setMargin
  setFrequencies = useIndicators.getState().actions.setFrequencies
  setBi = useIndicators.getState().actions.setBi

  constructor() {
    this.name = "subscription-indicator";

    this.MSubscriptionIndicator = SubscriptionIndicatorModel();
  }

  init = _.once(async () => {
    await Promise.all([
      this.getPotential(),
      this.getSubscribers(),
      this.getInvoicing(),
      this.getCancellations(),
      this.getMargin(),
      this.getFrequencies(),
    ])
  });



  getPotential = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('potencial');

    this.setPotential(result)
  };

  getSubscribers = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('assinantes');

    this.setSubscribers(result)
  };
  getInvoicing = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('faturamento');

    this.setInvoicing(result)
  };
  getCancellations = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('cancelamentos');

    this.setCancellations(result)
  };
  getMargin = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('margem');

    this.setMargin(result)
  };
  getFrequencies = async () => {
    const result = await this.MSubscriptionIndicator.getIndicator('frequencias');

    this.setFrequencies(result)
  };
  getBi = async (props) => {
    const result = await this.MSubscriptionIndicator.getBi(props);

    this.setBi(result)
  };
}
