import _ from "lodash";
import { dateFormatedToDB } from "../../functions";
import { PurchaseReportModel } from "../../models/reports";

export default class PurchaseReportController {
  constructor() {
    this.name = "reports-purchase";
    this.tableName = `${this.name}-table`;
    this.paginationName = `${this.name}-pagination`;

    this.MPurchaseReport = PurchaseReportModel();

    this.init();
  }

  init = async () => {
    this.findPurchaseReports()
  };

  findPurchaseReports = async () => {
    await this.MPurchaseReport.findPurchaseReports();
  };

  setPage = (page) => {
    this.MPurchaseReport.setPage(page);
  };

  setFilter = (filterData) => {
    const filter = _.pickBy({
      dateFrom: dateFormatedToDB(filterData.dateFrom),
      dateTo: dateFormatedToDB(filterData.dateTo),
    }, _.identity);

    this.MPurchaseReport.setFilter({
      ...filter,
      description: filterData.description,
      department: filterData.department,
      brand: filterData.brand,
    });
  };

  setSort = (sortData) => {
    const sort = _.pickBy({
      field: sortData.field,
      direction: sortData.direction,
    }, _.identity);

    this.MPurchaseReport.setSort(sort);
  };

  filterPurchases = async (e) => {
    if (e) e.preventDefault();
    await this.MPurchaseReport.filterPurchaseReports();
  };

  reportExport = () => {
    this.MPurchaseReport.export();
  };
}
