import RoyaltyStatementClass from "./RoyaltyStatement";
import PurchaseReportClass from "./PurchaseReport";

let MRoyaltyStatement = null;
let MPurchaseReport = null;

export function RoyaltyStatementModel() {
  if (MRoyaltyStatement) {
    return MRoyaltyStatement;
  }

  MRoyaltyStatement = new RoyaltyStatementClass();
  return MRoyaltyStatement;
}

export function PurchaseReportModel() {
  if (MPurchaseReport) {
    return MPurchaseReport;
  }

  MPurchaseReport = new PurchaseReportClass();
  return MPurchaseReport;
}
