import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import {
  Typography,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Grid,
  Button,
  Divider,
} from '@material-ui/core'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

import ModalType from '../../atoms/Modal'
import { clearNumber } from '../../../services/functions'
import { useMerchant } from '../../../store/global/useMerchant'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  hr: {
    textAlign: 'center',
  },
  spacing: {
    textAlign: 'center',
    marginTop: '30px',
  },
  title: {
    fontSize: '20px',
    paddingTop: '50px',
  },
}))

const hours = [
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
]
const weekDayList = [
  {
    id: 'monday',
    name: 'Segunda-Feira',
  },
  {
    id: 'tuesday',
    name: 'Terça-Feira',
  },
  {
    id: 'wednesday',
    name: 'Quarta-Feira',
  },
  {
    id: 'thursday',
    name: 'Quinta-Feira',
  },
  {
    id: 'friday',
    name: 'Sexta-Feira',
  },
  {
    id: 'saturday',
    name: 'Sábado',
  },
  {
    id: 'sunday',
    name: 'Domingo',
  },
]

function NewEmployee() {
  const merchant = useMerchant((st) => st.data.merchant);
  const history = useHistory()
  const defaultIds = []
  const motorista = history.location.search.search('motorista') >= 0

  const { register, control, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      item_ids: defaultIds,
      test: [
        {
          weekDay: 'sunday',
          opensAt: '8:00',
          closesAt: '17:20',
          breakStartsAt: '12:00',
          breakStopsAt: '13:00',
        },
      ],
    },
  })

  const hourStatus = watch()

  const value = '0'

  const classes = useStyles()
  const [sw, setSwitch] = useState(false)
  const [systemAccess, setSystemAccess] = useState(false)
  const [service, setService] = useState(false)
  const [check, setCheck] = useState([])

  const [error, setError] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleToggle = (value) => () => {
    const currentIndex = check.indexOf(value)
    const newChecked = [...check]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheck(newChecked)
  }

  const company = false

  const [stateButtom, setStateButtom] = React.useState(false)

  const handleCheck = (checkedId) => {
    const { item_ids: ids } = getValues()
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id) => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  const [employees, setEmployees] = useState()

  const handleChangeSw = () => {
    setSwitch(!sw)
  }

  const handleChangeService = () => {
    setService(!service)
  }

  const handleChangeSystemAccess = () => {
    setSystemAccess(!systemAccess)
  }

  const postNewEmployee = async (data, objTime) => {
    try {
      if (motorista) {
        const resp = await API.post('taxi-dog/driver/new', {
          cpf: data?.cpf,
          email: data?.email,
          name: `${data?.nome} ${data?.lastName}`,
          phoneNumber: data?.tel,
        })
        if (resp.status === 201) history.push('/profissionais?motorista')
        return
      }
      const resp = await API.post('user', {
        type: 'employee',
        name: `${data?.nome} ${data?.lastName}`,
        jobTitle: null,
        department: data?.departamento,
        email: data?.email,
        cpf: data?.cpf,
        crmv: data?.crmv,
        password: data?.password,
        employeeGroupId: motorista ? check : data?.item_ids[0],
        systemAccess,
        executesService: sw,
        active: service,
        phoneNumber: clearNumber(data?.tel),
        workingHoursDefinedByStore: company,
        workingHours: objTime,
      })
      if (resp.status === 201) history.push('/profissionais')
    } catch (err) {
      console.log('Erro:', err)
      setStateButtom(false)
      setError(true)
      setOpenModal(err?.response?.data?.friendly_message)
    }
  }

  const onSubmitStep1 = (data) => {
    const shoppingHoursEdit = weekDayList.map((day) => ({
      weekDay: day.id,
      opensAt: data[`incio-${day.id}`],
      closesAt: data[`fim-${day.id}`],
      breakStartsAt: data[`breakStartsAt-${day.id}`],
      breakStopsAt: data[`breakStopsAt-${day.id}`],
      active: data[`status-${day.id}`],
    }))

    const objTime = shoppingHoursEdit.filter((item) => item?.active)

    const shoppingHours = objTime.map((day) => ({
      weekDay: day.weekDay,
      opensAt: day.opensAt,
      closesAt: day.closesAt,
      breakStartsAt: day.breakStartsAt,
      breakStopsAt: day.breakStopsAt,
    }))

    postNewEmployee(data, shoppingHours)
  }

  const getEmployees = async () => {
    try {
      const resp = await API.get('employees/groups')
      resp && setEmployees(resp.data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getEmployees()
  }, [])


  //
  return (
    <BasePage
      pageTitle={motorista ? 'Adicionar Motorista' : 'Adicionar Profissional'}
    >
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        verification={false}
        err={error}
      />

      <form className={classes.root} onSubmit={handleSubmit(onSubmitStep1)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="h3"
              className={classes.title}
            >
              Dados Gerais
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              {!motorista && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    as={
                      <TextField
                        id="departamento"
                        select
                        fullWidth
                        required
                        label="Departamento"
                        variant="outlined"
                      >
                        <MenuItem key="Banho e Tosa" value="Banho e Tosa">
                          Banho e Tosa
                        </MenuItem>
                        {!!merchant.hasTokenVarejo && (
                          <MenuItem
                            key="Clínica Veterinária"
                            value="Clínica Veterinária"
                          >
                            Clínica Veterinária
                          </MenuItem>
                        )}
                      </TextField>
                    }
                    name="departamento"
                  />
                </Grid>
              )}
              {!!merchant.hasTokenVarejo && (
                <Grid item xs={6}>
                  <TextField
                    id="crmv"
                    label="CRMV"
                    name="crmv"
                    variant="outlined"
                    fullWidth
                    inputRef={register}
                    placeholder="CRMV"
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  variant="outlined"
                  fullWidth
                  required
                  inputRef={register}
                  placeholder="CPF"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="name"
                  label="Nome"
                  name="nome"
                  required
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  placeholder="Primeiro Nome"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastName"
                  label="Sobrenome"
                  name="lastName"
                  required
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  placeholder="Sobrenome"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="tel"
                  label="Telefone"
                  name="tel"
                  required
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  placeholder="Telefone"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="E-mail"
                  name="email"
                  required
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  placeholder="E-mail"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={service}
                  onChange={handleChangeService}
                  color="primary"
                  name="ativo"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Ativo"
            />
          </Grid>

          {!motorista && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sw}
                    onChange={handleChangeSw}
                    color="primary"
                    name="ativo"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Disponível para agendamento"
              />
            </Grid>
          )}
          {!motorista && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={systemAccess}
                    onChange={handleChangeSystemAccess}
                    color="primary"
                    name="systemAccess"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Acessa o sistema"
              />
            </Grid>
          )}
        </Grid>

        {systemAccess && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="password"
                label="Senha"
                name="password"
                inputRef={register}
                variant="outlined"
                fullWidth
                type="password"
                placeholder="Senha"
              />
            </Grid>
          </Grid>
        )}

        <Divider className={classes.spacing} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="h3"
              className={classes.title}
            >
              Perfil de Acesso
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="item_ids"
              render={(props) => (
                <FormGroup>
                  {employees?.map((item) => {
                    if (motorista && item.name !== 'Motorista') {
                      return <></>
                    }
                    if (!merchant.hasTokenVarejo && item.name === 'Veterinário') {
                      return <></>
                    }


                    if (motorista && item.name === 'Motorista') {
                      handleToggle(item.id)
                    }
                    if (!motorista && item.name === 'Motorista') {
                      return <></>
                    }
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            onClick={handleToggle(item.id)}
                            onChange={() =>
                              props.onChange(handleCheck(item.id))
                            }
                            defaultChecked={motorista || check[0] === item.id}
                            tabIndex={check.indexOf(value) !== -1}
                            disabled={
                              check.length !== 0 && check[0] !== item.id
                            }
                          />
                        }
                        key={item.id}
                        label={item.name}
                      />
                    )
                  })}
                </FormGroup>
              )}
              control={control}
            />
          </Grid>
        </Grid>

        <Divider className={classes.spacing} />
        {!motorista && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h3"
                  className={classes.title}
                >
                  Horários
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <h4>Dia da Semana</h4>
              </Grid>

              <Grid item xs={2}>
                <h4>Status</h4>
              </Grid>

              <Grid item xs={4} className={classes.hr}>
                <h4>Horário de Trabalho</h4>
              </Grid>

              <Grid item xs={4} className={classes.hr}>
                <h4>Horário da Pausa</h4>
              </Grid>
            </Grid>

            <Divider />

            {weekDayList?.map((day, index) => (
              <Grid
                container
                spacing={3}
                xs={12}
                key={day.weekDay}
                style={{ marginTop: '10px' }}
              >
                <Grid item xs={2}>
                  <p>{weekDayList[index].name}</p>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    defaultChecked={false}
                    color="primary"
                    name={`status-${day.id}`}
                    inputRef={register}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    control={control}
                    defaultValue="08:00"
                    disabled={!hourStatus[`status-${day.id}`]}
                    required={hourStatus[`status-${day.id}`]}
                    name={`incio-${day.id}`}
                    as={
                      <TextField
                        fullWidth
                        select
                        label="INICIO"
                        variant="outlined"
                      >
                        {hours.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    control={control}
                    defaultValue="18:00"
                    name={`fim-${day.id}`}
                    disabled={!hourStatus[`status-${day.id}`]}
                    required={hourStatus[`status-${day.id}`]}
                    as={
                      <TextField
                        fullWidth
                        select
                        label="FIM"
                        variant="outlined"
                      >
                        {hours.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    control={control}
                    defaultValue="12:00"
                    name={`breakStartsAt-${day.id}`}
                    disabled={!hourStatus[`status-${day.id}`]}
                    required={hourStatus[`status-${day.id}`]}
                    as={
                      <TextField
                        fullWidth
                        select
                        label="INICIO"
                        variant="outlined"
                      >
                        {hours.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    control={control}
                    defaultValue="13:00"
                    name={`breakStopsAt-${day.id}`}
                    disabled={!hourStatus[`status-${day.id}`]}
                    required={hourStatus[`status-${day.id}`]}
                    as={
                      <TextField
                        fullWidth
                        select
                        label="FIM"
                        variant="outlined"
                      >
                        {hours.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>
              </Grid>
            ))}

            <Divider className={classes.spacing} />
          </>
        )}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: '20px' }}
        >
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={stateButtom}
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push('/profissionais?motorista')}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </form>
    </BasePage>
  )
}

export default NewEmployee
