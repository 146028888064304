import React from 'react'

// Material ui
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Alert from '@mui/material/Alert'
import { Snackbar } from '@material-ui/core'

// Material ui icones
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useAuth } from '../../../../providers/auth'
import API from '../../../../services/api'

// Style
import { useStyles } from '../styles'
import { useMerchant } from '../../../../store/global/useMerchant'

function Steps2({
  handleStep,
  resultSearchService,
  resultSearchEmployees,
  activeStep,
}) {
  const classes = useStyles()
  const { modules, setModules } = useAuth()
  const [expanded, setExpanded] = React.useState(false)
  const [service, setService] = React.useState([])
  const [newService, setNewService] = React.useState(false)
  const [age, setAge] = React.useState('service')
  const [newCategory, setNewCategory] = React.useState({})
  const [active, setActive] = React.useState(false)
  const [message, setMessage] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(false)
  const [values, setValues] = React.useState({
    amount: '',
  })

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setNewService(setNewService && false)
  }

  const handleChangeValue = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleChangeInput = (event) => {
    setAge(event.target.value)
  }

  const handleChangeAjust = (value) => {
    setDisableButton(true)
    const divService = parseInt(values.amount, 10) / 100
    const newService = service.map((item) => {
      if (item.categoryId === value) {
        if (item.exceptions?.length > 0) {
          if (age === 'service') {
            const newExceptions = item.exceptions.map((exception) => {
              if (exception.service?.id === newCategory?.id) {
                return {
                  ...exception,
                  commissionValue: divService,
                }
              }
              return exception
            })

            const newFindExceptions = item.exceptions.find(
              (exception) => exception.service?.id === newCategory?.id,
            )

            if (!newFindExceptions) {
              return {
                ...item,
                exceptions: [
                  ...item.exceptions.map((item) => item),
                  {
                    commissionValue: divService,
                    exceptionType: 'Serviço',
                    service: newCategory,
                  },
                ],
              }
            }
            return { ...item, exceptions: newExceptions }
          }
          const newExceptions = item.exceptions.map((exception) => {
            if (exception.employee?.id === newCategory?.id) {
              return {
                ...exception,
                commissionValue: divService,
              }
            }
            return exception
          })

          const newFindExceptions = item.exceptions.find(
            (exception) => exception.employee?.id === newCategory?.id,
          )

          if (!newFindExceptions) {
            return {
              ...item,
              exceptions: [
                ...item.exceptions.map((item) => item),
                {
                  commissionValue: divService,
                  exceptionType: 'Profissional',
                  employee: newCategory,
                },
              ],
            }
          }
          return { ...item, exceptions: newExceptions }
        }
        if (age === 'service') {
          return {
            ...item,
            exceptions: [
              {
                commissionValue: divService,
                exceptionType: 'Serviço',
                service: newCategory,
              },
            ],
          }
        }
        return {
          ...item,
          exceptions: [
            {
              commissionValue: divService,
              exceptionType: 'Profissional',
              employee: newCategory,
            },
          ],
        }
      }
      return item
    })
    setService(newService)
    setActive(true)
  }

  const getService = async () => {
    const hasTokenVarejo = useMerchant.getState().data.merchant.hasTokenVarejo
    try {
      const { data } = await API.get(
        'employees/commissions/config-by-categories',
      )

      if (!hasTokenVarejo) {
        const noTokenServices = data.filter((item) => !["ANESTESIA", "APLICACOES", "CIRURGIAS", "CONSULTA VETERINARIA", "CONSULTA VETERINARIA ESPECIALISTA", "VACINACOES", "SERVICOS CLINICA", "CONVENIOS", "OBSERVACAO VETERINARIA", "EXAMES"].includes(item.categoryName))
        setService(noTokenServices)
        return
      }
      setService(data)

    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleSellers = (result) => {
    setNewCategory({
      id: result.id ? result.id : result.employeeId,
      name: result.name,
    })
  }

  const handleComisao = (valor, value) => {
    const divisao = parseInt(valor, 10) / 100
    const newService = service.map((item) => {
      if (item.categoryId === value) {
        return {
          ...item,
          defaultValue: divisao,
        }
      }
      return item
    })
    setService(newService)
    setActive(true)
  }

  const onSubmit = async () => {
    setDisableButton(true)
    const payload = {
      categoryList: service,
    }

    try {
      const result = await API.post(
        'employees/commissions/config-by-categories',
        payload,
      )
      if (result?.status === 201) {
        if (activeStep !== 2) handleStep(2)
        setMessage(result?.data?.message)
        setDisableButton(false)
        setModules({
          ...modules,
          commission: { schedule: true },
        })
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }

  const onClose = (idClose, index, categoryId, channel) => {
    if (channel === 'service') {
      const newArray = service[index]?.exceptions.filter(
        (item) => item.service?.id !== idClose,
      )
      const newService = service.map((item) => {
        if (item.categoryId === categoryId) {
          return {
            ...item,
            exceptions: newArray,
          }
        }
        return item
      })
      setService(newService)
      setActive(true)
    } else {
      const newArray = service[index]?.exceptions.filter(
        (item) => item.employee?.id !== idClose,
      )
      const newService = service.map((item) => {
        if (item.categoryId === categoryId) {
          return {
            ...item,
            exceptions: newArray,
          }
        }
        return item
      })
      setService(newService)
      setActive(true)
    }
  }

  React.useEffect(() => {
    getService()
  }, [])

  React.useEffect(() => {
    if (active) onSubmit()
  }, [service])
  return (
    <div>
      {service?.map((item, index) => (
        <Accordion
          key={item.categoryName}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: '33%', flexShrink: 0 }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {item.categoryName}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <TextField
                type="number"
                className={classes.accordionNumber}
                defaultValue={
                  item.defaultValue === null
                    ? 0
                    : (item.defaultValue * 100).toFixed()
                }
                label="Comissão"
                onBlur={({ target }) =>
                  handleComisao(target.value, item.categoryId)
                }
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography
              onClick={() => setNewService(!newService)}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#CCD219DE',
                cursor: 'pointer',
              }}
            >
              <AddCircleIcon
                style={{ fontSize: '35px', marginRight: '15px' }}
              />{' '}
              <span>Nova exceção</span>
            </Typography>
          </AccordionDetails>

          {newService && (
            <AccordionDetails className={classes.accordionDetails}>
              <Box sx={{ minWidth: 120 }} style={{ marginRight: '20px' }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    onChange={handleChangeInput}
                  >
                    <MenuItem value="service">Serviço</MenuItem>
                    <MenuItem value="profissional">Profissional</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {age === 'service' ? (
                <Autocomplete
                  disablePortal
                  style={{ marginRight: '10px' }}
                  id="combo-box-demo"
                  options={resultSearchService}
                  sx={{ width: 400 }}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Pesquisar" />
                  )}
                  onChange={(i, value) => handleSellers(value)}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  style={{ marginRight: '10px' }}
                  id="combo-box-demo"
                  options={resultSearchEmployees}
                  sx={{ width: 400 }}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Pesquisar" />
                  )}
                  onChange={(i, value) => handleSellers(value)}
                />
              )}

              <FormControl sx={{ m: 1 }} style={{ width: '120px' }}>
                <TextField
                  id="outlined-adornment-amount"
                  type="number"
                  value={values.amount}
                  onChange={handleChangeValue('amount')}
                  label="Comissão"
                  InputProps={{
                    inputProps: { min: 0, max: 100 },
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => handleChangeAjust(item.categoryId)}
                  disabled={disableButton}
                  style={{
                    background: '#CCD219',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    width: '120px',
                    marginRight: '20px',
                  }}
                >
                  Adicionar
                </Button>
              </Stack>
            </AccordionDetails>
          )}

          {item?.exceptions?.map((itemEx) => (
            <AccordionDetails key={itemEx?.service?.id}>
              {itemEx?.service && (
                <Typography style={{ display: 'flex' }}>
                  <span style={{ width: '620px' }}>
                    <span>Serviço:</span> {itemEx?.service?.name} / Comissão:{' '}
                    {itemEx.commissionValue}
                  </span>
                  <span
                    onClick={() =>
                      onClose(
                        itemEx?.service?.id,
                        index,
                        item.categoryId,
                        'service',
                      )
                    }
                  >
                    <DeleteForeverIcon
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                </Typography>
              )}

              {itemEx?.employee && (
                <Typography style={{ display: 'flex' }}>
                  <span style={{ width: '620px' }}>
                    <span>Profissional:</span> {itemEx?.employee?.name} /
                    Comissão: {itemEx.commissionValue}
                  </span>
                  <span
                    onClick={() =>
                      onClose(
                        itemEx?.employee?.id,
                        index,
                        item.categoryId,
                        'profissional',
                      )
                    }
                  >
                    <DeleteForeverIcon
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                </Typography>
              )}
            </AccordionDetails>
          ))}
        </Accordion>
      ))}

      {service && (
        <Typography
          sx={{ width: '33%', flexShrink: 0 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            fontSize: '12px',
            marginTop: '10px',
            color: 'red',
          }}
        >
          *Ao alterar o valor da comissão, não alteram os valores já
          finalizados.
        </Typography>
      )}

      <Stack
        spacing={2}
        direction="row"
        justifyItems="center"
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          disabled={disableButton}
          style={{
            background: '#CCD219',
            fontSize: '14px',
            textTransform: 'capitalize',
            fontWeight: '400',
            width: '120px',
            marginRight: '20px',
          }}
        >
          Salvar
        </Button>
      </Stack>

      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={() => {
          setMessage(false)
        }}
      >
        <Alert severity={message === 'error' ? 'error' : 'success'}>
          {message === 'error' ? errMsg : message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Steps2
