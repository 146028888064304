import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

import API from "../../api";

import { formatResponse } from "../../formatResponses/reports";
import { usePurchaseReports } from "../../../store/reports/usePurchaseReport";

export default class PurchaseReportModel {
  setPurchaseReport = usePurchaseReports.getState().actions.setPurchaseReport;
  setPurchaseHighlightReport = usePurchaseReports.getState().actions.setPurchaseHighlightReport;

  clearPurchaseReport = usePurchaseReports.getState().actions.clearPurchaseReport;

  setLoading = usePurchaseReports.getState().actions.setLoading;
  setWaiting = usePurchaseReports.getState().actions.setWaiting;
  setFilterData = usePurchaseReports.getState().actions.setFilter;
  setSortData = usePurchaseReports.getState().actions.setSort;
  setPaginationPage = usePurchaseReports.getState().actions.setPaginationPage;

  constructor() {
    this.name = "reports-store-purchase";
    this.reportName = `${this.name}-report`;

    this.startDate = moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");

    this.setFilterData({
      dateFrom: this.startDate,
      dateTo: this.endDate,
    });
  }

  export() {
    console.log('Exportar Relátórtio de Purchase');
  }

  setFilter(filterProps) {
    this.setFilterData(filterProps);
  }

  setSort(sortProps) {
    this.setSortData(sortProps);
  }

  async setPage(page) {
    const pageExists = usePurchaseReports.getState().data.purchaseReport[page];
    this.setPaginationPage(page);

    if (!pageExists) await this.findPurchaseReports();
  }

  async findPurchaseReports() {
    await this.getPurchaseReport();
  }

  async filterPurchaseReports() {
    this.setLoading(true);
    this.setPaginationPage(0);
    this.clearPurchaseReport();
    this.setPurchaseHighlightReport([], true)
    await this.getPurchaseReport();
    this.setLoading(false);
  }

  async getPurchaseReport() {
    try {
      this.setWaiting(true);
      const options = this.options();
      const { data } = await API.post("/fetch-order-data-by-store2", options);

      const formattedPurchaseReport = formatResponse.purchaseReports(Object.values(data.results));
      const formattedPurchaseHighlightsReport = formatResponse.purchaseReports(Object.values(data.highlights));
      const pagination = formatResponse.pagination(data.pagination);

      this.setPurchaseReport(formattedPurchaseReport, pagination);
      this.setPurchaseHighlightReport(formattedPurchaseHighlightsReport);
      this.setWaiting(false);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      this.setWaiting(false);
      return null;
    }
  }

  sort() {
    const sort = usePurchaseReports.getState().data.sort;

    const direction = sort.direction === "1" ? 1 : -1;
    let sortData = {
      faturamento: direction,
      quantidadeVendidaCurva: direction,
      sugestaoCompra: direction
    }

    switch (sort.field) {
      case "suggestion-purchase":
        sortData = { sugestaoCompra: direction }
        break;
      case "invoicing":
        sortData = { faturamento: direction }
        break;
      case "sale":
        sortData = { quantidadeVendidaCurva: direction }
        break;
      case "abc":
        sortData = {
          curva: direction * -1,
          faturamento: direction,
          quantidadeVendidaCurva: direction,
        }
        break;
      default:
        sortData = {
          noSugestion: direction * -1,
          curva: direction * -1,
          sugestaoCompra: direction,
        }
    }
    return sortData
  }

  options() {
    const filter = usePurchaseReports.getState().data.filter;
    const pagination = usePurchaseReports.getState().data.pagination;
    const highlighteds = usePurchaseReports.getState().data.highlighteds;

    return {
      highlighted: highlighteds || 2,
      sort: this.sort(),
      filters: {
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        descricao: filter.description,
        marca: filter.brand,
        departamento: filter.department !== "*" ? filter.department : undefined,
      },
      pagination: {
        currentPage: pagination.page || 0,
        perPage: pagination.perPage || 10,
      }
    };
  }
}
