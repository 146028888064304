import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";

const defaultData = {
  subscribers: false,
  invoicing: false,
  cancellations: false,
  margin: false,
  potential: false,
  frequencies: false,
  bi: false,
};

export const useIndicators = create((set) => ({
  data: defaultData,
  actions: {
    setPotential: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.potential = data;
        })
      ),
    setSubscribers: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.subscribers = data;
        })
      ),
    setInvoicing: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.invoicing = data;
        })
      ),
    setCancellations: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.cancellations = data;
        })
      ),
    setMargin: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.margin = data;
        })
      ),
    setFrequencies: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.frequencies = data;
        })
      ),
    setBi: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.bi = data;
        })
      ),
  },
}));
