import React, { useEffect } from "react";

import { usePurchaseReports } from "../../../../store/reports/usePurchaseReport";

import { PurchaseReportController } from "../../../../services/controllers/reports";

import { Table } from "../../../../components/ui/Table";
import { Pagination } from "../../../../components/ui/Pagination";
import { Badge } from "../../../../components/ui/Badge";

export function PurchaseReportTable() {
  const CPurchaseReport = PurchaseReportController();

  const page = usePurchaseReports((st) => st.data.pagination.page);
  const isWaiting = usePurchaseReports((st) => st.data.isWaiting);
  const paginationData = usePurchaseReports((st) => st.data.pagination);
  const purchaseReports = usePurchaseReports((st) => st.data.purchaseReport);

  return (
    <Table.Root name={CPurchaseReport.tableName} config>
      <Table.Header
        columns={[
          "EAN:130px",
          "Produto",
          "Estoque:80px",
          "Média Mensal:125px",
          "Departamento:220px",
          "Marca:150px",
          "Qtd. Vendida:150px",
          "Curva:60px",
          "Sugestão Compra:160px",
        ]}
      />

      <Table.Content isLoading={isWaiting}>
        {!!purchaseReports[page] &&
          purchaseReports[page].map((statement) => (
            <Table.Line key={statement.id}>
              <Table.Item>{statement.codigoBarras}</Table.Item>
              <Table.Item>{statement.description}</Table.Item>
              <Table.Item className="text-center">{statement.stock}</Table.Item>
              <Table.Item className="text-center">{statement.monthlyAverage}</Table.Item>
              <Table.Item>{statement.department}</Table.Item>
              <Table.Item>{statement.brand}</Table.Item>
              <Table.Item className="text-center">{statement.quantitySold}</Table.Item>
              <Table.Item className="text-center">
                <Badge.Root color={statement.curveColor} >
                  <Badge.Text>{statement.curva}</Badge.Text>
                </Badge.Root>
              </Table.Item>
              <Table.Item className="text-center">{statement.suggestionPurchase}</Table.Item>
            </Table.Line>
          ))}
      </Table.Content>
      <Pagination.Root
        name={CPurchaseReport.paginationName}
        totalPages={paginationData.totalPages}
        currentPage={paginationData.page}
        onChange={CPurchaseReport.setPage}
      >
        <Pagination.Back />
        <Pagination.Pages />
        <Pagination.Forward />
      </Pagination.Root>
    </Table.Root>
  );
}
