import React, { useContext, useEffect } from "react";
import CountUp from "react-countup";
import { Summary } from "../../../ui/Summary";
import { CalendarCheck, Bath, ShowerHead } from "lucide-react";
import { SubscriptionIndicatorController } from "../../../../services/controllers/subscription";
import { ReportContext } from "../../../../providers/report";
import { useMerchant } from "../../../../store/global/useMerchant";
import { useIndicators } from "../../../../store/subscription/useIndicators";

export function DashboardSummary() {
  const { firstData, lastData, infoRepoart } = useContext(ReportContext)

  const merchant = useMerchant((st) => st.data.merchant);
  const biData = useIndicators(sr => sr.data.bi)

  const CLSubscriptionIndicator = SubscriptionIndicatorController()

  useEffect(() => {
    if (!!merchant.id) {
      CLSubscriptionIndicator.getBi({ startDate: firstData, endDate: lastData, storeId: merchant.id })
    }
  }, [merchant.id, infoRepoart])

  return (
    <Summary.Root>
      <Summary.Box>
        <Summary.Icon icon={CalendarCheck} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={!!biData ? biData.agendamentos.total : 0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total de Agendamentos</Summary.Text>
          <Summary.SubTitle>Orçamento Enviado: {!!biData ? biData.agendamentos.totalOrcamentoEnviado : 0}</Summary.SubTitle>
          {!merchant.hasTokenVarejo && <Summary.SubTitle>Pago: {!!biData ? biData.agendamentos.totalPago : 0}</Summary.SubTitle>}
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={Bath} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={!!biData ? biData.banhos.total : 0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total de Banhos</Summary.Text>
          <Summary.SubTitle>Orçamento Enviado: {!!biData ? biData.banhos.totalOrcamentoEnviado : 0}</Summary.SubTitle>
          {!merchant.hasTokenVarejo && <Summary.SubTitle>Pago: {!!biData ? biData.banhos.totalPago : 0}</Summary.SubTitle>}
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={ShowerHead} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={!!biData ? biData.adicionais.total : 0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total de Adicionais</Summary.Text>
          <Summary.SubTitle>Orçamento Enviado: {!!biData ? biData.adicionais.totalOrcamentoEnviado : 0}</Summary.SubTitle>
          {!merchant.hasTokenVarejo && <Summary.SubTitle>Pago: {!!biData ? biData.adicionais.totalOrcamentoEnviado : 0}</Summary.SubTitle>}
        </Summary.TextContent>
      </Summary.Box>
    </Summary.Root>
  )
}
