import _ from "lodash";
import moment from "moment";
import Mustache from "mustache";
import slugify from "slugify";

import { cpf } from 'cpf-cnpj-validator';


import { Dog } from "lucide-react";

import { VACCINATION_STATUS_NAME } from "../store/reports/veterinary/constants";
import { SCHEDULE_BILLING_ERRORS, SCHEDULE_STATUS_TITLE } from "../store/schedule/constants";
import {
  MEDICINE_DURATION,
  MEDICINE_FREQUENCY,
  MEDICINE_MEASURE,
  MEDICINE_PHARMACY_TYPE,
  MEDICINE_USAGE,
  PDF_GENERATION_STATUS_COLOR,
  PDF_GENERATION_STATUS_ICON,
  PDF_GENERATION_STATUS_NAME,
  PREVIEW_LIST_PLACEHOLDER,
  SERVICE_STATUS,
  VACCINATION_PROTOCOL_STATUS_COLOR,
  VACCINATION_PROTOCOL_STATUS_ICON,
  VACCINATION_PROTOCOL_STATUS_NAME,
  VACCINATION_STATUS_FIELDS,
  VACCINE_DURATION,
  VACCINE_FREQUENCY,
  VETERINARY_CARE_ABLE_STATUS,
  VETERINARY_CARE_STATUS,
  VETERINARY_CARE_STATUS_NAME,
  VETERINARY_CARE_STATUS_PLACEHOLDER,
} from "../store/service/veterinary/constants";
import { darkTheme, lightTheme, tempTheme } from "../styles/Theme";
import {
  ARTICLES,
  BUDDY,
  CALENDAR,
  CASTRATED,
  DEPARTMENT_EMPLOYEE_TYPE_NAME,
  DEPARTMENT_NAME_VALUE,
  DEPARTMENT_VALUE_NAME,
  FILE_EXTENSIONS_TYPE,
  OUR,
  PAGINATION,
  PET_CASTRATED,
  PET_COMPLETE_REGISTRATION_FIELDS,
  PET_GENDERS,
  PET_SPECIES_NAME,
  RECOMMENDATION_DEPARTMENT,
  RECOMMENDATION_DEPARTMENT_ICON,
  SCHEDULING_DEPARTMENT_STATUS_CODE,
  SCHEDULING_DEPARTMENT_STATUS_COLOR,
  SEND_SCHEDULE_STATUS_MESSAGEN,
  SUBSCRIBER_CYCLE_STATUS,
  SUBSCRIBER_STATUS_COLOR,
  USER_TYPE_NAME_LEVEL,
  USER_TYPE_NAME_VALUE
} from "../styles/constant";
import { CURVE_COLOR } from "../store/reports/constants";

export function getSubscriberStatusColor(status) {
  if (!status) return "transparent";

  if (!!SUBSCRIBER_STATUS_COLOR[status]) return SUBSCRIBER_STATUS_COLOR[status];
  return "transparent";
}

export function getSubscriberStatusText(status) {
  if (!status) return "";

  if (!!SUBSCRIBER_CYCLE_STATUS[status]) return SUBSCRIBER_CYCLE_STATUS[status];
  return "";
}

export function getCurrentThemeStyle() {
  const department = getDepartmentName()

  switch (department) {
    case 'Dra.Mei': return darkTheme
    case 'Petland': return lightTheme
    default: return tempTheme
  }
}

export function getScheduleCycleBillingErrorMessage(acquirerMessage) {
  if (!acquirerMessage) return '';

  const hasCode = acquirerMessage.match(/\[([^\]]+)\]/);
  if (!hasCode) return acquirerMessage

  const code = hasCode[1]
  return SCHEDULE_BILLING_ERRORS[code] || SCHEDULE_BILLING_ERRORS['*']
}


export function groupByProperty(data) {
  if (!data) return {}
  const result = {};

  _.forOwn(data, (value, key) => {
    const groups = value.group.split('/');
    let currentLevel = result;

    groups.forEach((group, index) => {
      if (!currentLevel[group]) {
        currentLevel[group] = (index === groups.length - 1) ? {} : {};
      }

      if (index === groups.length - 1) {
        currentLevel[group][key] = value;
      } else {
        currentLevel = currentLevel[group];
      }
    });
  });

  return result;
}


export const perc2color = ({ center, percent }) => {
  const goodEnd = 100 - center;
  const redRatio = 50 / center;

  let color = 0;
  if (percent < center) {
    color = Math.round(percent * redRatio);
  } else {
    color = Math.round(50 + (percent - center) * (50 / goodEnd));
  }
  return `hsl(${color}, 70%, 47%)`;
};


export function formatSuggestions(suggestions, parserOptions = {}) {
  const defaultParser = { id: 'id', value: 'name' }
  const parser = {
    ...defaultParser,
    ...parserOptions
  }

  return _.map(suggestions, (suggestion) => {
    const parsedSuggestion = {}

    if (typeof suggestion === 'string') {
      parsedSuggestion.id = suggestion;
      parsedSuggestion.value = suggestion;

      return parsedSuggestion
    }

    Object.keys(parser).forEach(key => {
      parsedSuggestion[key] = suggestion[parser[key]]
    })

    return parsedSuggestion
  });

}

export function formatMerchantName(merchant) {
  const merchantName = merchant
    .toLowerCase()
    .replace("petland", "")
    .replace("pet land", "")
    .replace("dra mei", "")
    .replace("dramei", "")
    .replace("dra.mei", "").trim();

  return formatName(merchantName);
}

export function formatToFind(data) {
  if (!data) return data

  if (typeof data === 'string') {
    return data.toLocaleLowerCase().replaceAll(" ", "")
  }

  return data.map((value) => value.toLocaleLowerCase().replaceAll(" ", ""))
}

export function getTotalDosesWithFrequencyInDuration(
  durationData,
  frequencyData
) {
  const { duration, durationPeriod } = durationData;
  const { frequency, frequencyPeriod } = frequencyData;

  const durationInFrequencyPeriod = getPeriodDiff(duration, {
    from: durationPeriod,
    to: frequencyPeriod,
  });

  const totalDoses = Math.floor(durationInFrequencyPeriod / frequency);

  return totalDoses;
}

export function getVaccinationProtocolStatusBadge(status) {
  if (!status) {
    return {
      type: "badge",
      icon: Dog,
      value: "Erro",
      props: {
        status: "danger",
        style: { maxWidth: "fit-content" },
      },
    };
  }

  return {
    type: "badge",
    icon: VACCINATION_PROTOCOL_STATUS_ICON[status],
    value: VACCINATION_PROTOCOL_STATUS_NAME[status][0],
    props: {
      outline: status === "applying",
      status: VACCINATION_PROTOCOL_STATUS_COLOR[status],
      style: { maxWidth: "fit-content" },
    },
  };
}

export function getPDFGenerateStatusBadge(status) {
  if (!status) {
    return {
      type: "badge",
      icon: Dog,
      value: "Erro",
      props: {
        status: "danger",
        style: { maxWidth: "fit-content" },
      },
    };
  }

  return {
    type: "badge",
    icon: PDF_GENERATION_STATUS_ICON[status],
    value: PDF_GENERATION_STATUS_NAME[status][0],
    props: {
      // outline: status === "notGenerated",
      status: PDF_GENERATION_STATUS_COLOR[status],
      style: { maxWidth: "fit-content" },
    },
  };
}

export function getAgeByBirth(momentDate) {
  const diff = moment.duration(moment().diff(momentDate));

  const years = diff.years();
  const months = diff.months();
  const days = diff.days();

  const yearsText = years > 1 ? `${years} anos` : `${years} ano`;
  const monthsText = months > 1 ? `${months} meses` : `${months} mês`;
  const daysText = days > 1 ? `${days} dias` : `${days} dia`;

  let fullText = "";

  if (years && months && days) {
    fullText = `${yearsText}, ${monthsText} e ${daysText}`;
  }
  if (years && months && !days) {
    fullText = `${yearsText} e ${monthsText}`;
  }
  if (years && !months && days) {
    fullText = `${yearsText} e ${daysText}`;
  }
  if (!years && months && days) {
    fullText = `${monthsText} e ${daysText}`;
  }
  if (!years && !months && days) {
    fullText = `${daysText}`;
  }

  return {
    formattedAge: {
      full: fullText,
      years: yearsText,
      months: monthsText,
      days: daysText,
    },
    years,
    months,
    days,
  };
}

export function autoFormatMedicineDosageInformation(medicineProps) {
  let information = "Administrar";


  const isSelf = medicineProps.fillStyle === "self";
  const isContinuous = medicineProps?.durationPeriod === "continuous";

  if (isSelf && !medicineProps.dose) return "";

  const doseWord = medicineProps.dose <= 1 ? 1 : 2;
  const frequencyWord = medicineProps?.frequency <= 1 ? 1 : 2;
  const durationWord = medicineProps?.duration <= 1 ? 1 : 2;

  if (!isSelf) {
    return medicineProps.freeText;
  }

  const formattedDose = medicineProps.dose.toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const doseMeasureWord = getMedicineMeasure(medicineProps.measure)[doseWord];
  const frequencyPeriodWord = getMedicineFrequency(
    medicineProps.frequencyPeriod
  )[frequencyWord].toLowerCase();
  const durationPeriodWord = getMedicineDuration(medicineProps.durationPeriod)[
    durationWord
  ].toLowerCase();

  if (!!medicineProps.dose) {
    information += ` ${formattedDose} ${doseMeasureWord}`;
  }

  if (!!medicineProps.frequency) {
    information += ` a cada ${medicineProps.frequency} ${frequencyPeriodWord}`;
  }

  if (!!medicineProps.duration && !isContinuous) {
    information += ` por ${medicineProps.duration} ${durationPeriodWord}`;
  }

  if (isContinuous) {
    information += `. Uso contínuo`;
  }

  return information + ".";
}

export function formatMedicineQuantity(medicineProps) {
  const isContinuous = medicineProps.durationPeriod === "continuous";
  const isSelf = medicineProps.fillStyle === "self";
  const quantityWord = medicineProps.quantity <= 1 ? "Unidade" : "Unidades";

  if (!medicineProps?.quantity) return ''

  const isText = isNaN(medicineProps.quantity);


  const continuousText =
    isText &&
    slugify(medicineProps?.quantity).toLocaleLowerCase().includes("continu");

  if ((isContinuous && isSelf) || continuousText) {
    return "Uso contínuo";
  }

  if (!medicineProps.quantity || medicineProps.quantity === "0") return "";

  return `${medicineProps.quantity} ${isSelf ? quantityWord : ""}`;
}

export function getSchedulingDepartmentStatusColor(status, that = false) {
  if (!status) return "transparent";
  const department = that || getCurrentDepartment()

  if (!!SCHEDULING_DEPARTMENT_STATUS_COLOR[department][status]) return SCHEDULING_DEPARTMENT_STATUS_COLOR[department][status];

  return "transparent";
}

export function getSchedulingDepartmentStatusCode(status, that = false) {
  if (!status) return "";
  const department = that || getCurrentDepartment()

  if (!!SCHEDULING_DEPARTMENT_STATUS_CODE[department][status]) return SCHEDULING_DEPARTMENT_STATUS_CODE[department][status];

  return "";
}

export function getSchedulingStatusTitle(status) {
  const storeName = getStoreName()
  if (!status) return storeName;

  if (!!SCHEDULE_STATUS_TITLE[status]) return SCHEDULE_STATUS_TITLE[status];

  return "";
}

export function getScheduleMessageTemplateByStatus(status) {
  if (!status) return "";

  if (!!SEND_SCHEDULE_STATUS_MESSAGEN[status]) return SEND_SCHEDULE_STATUS_MESSAGEN[status];

  return "";
}


export function getCurveColor(code) {
  if (!code) return CURVE_COLOR["ZZ"];
  if (!!CURVE_COLOR[code.toUpperCase()]) return CURVE_COLOR[code.toUpperCase()];

  return CURVE_COLOR["ZZ"];
}

export function getVaccineFrequency(frequency) {
  if (!frequency) return ["", "", ""];

  if (!!VACCINE_FREQUENCY[frequency]) return VACCINE_FREQUENCY[frequency];

  return ["", "", ""];
}

export function getVaccineDuration(duration) {
  if (!duration) return ["", "", ""];

  if (!!VACCINE_DURATION[duration]) return VACCINE_DURATION[duration];

  return ["", "", ""];
}

export function getMedicineUsage(usage) {
  if (!usage) return ["", "", ""];

  if (!!MEDICINE_USAGE[usage]) return MEDICINE_USAGE[usage];

  return ["", "", ""];
}

export function getPetSpecieName(specie) {
  if (!specie) return ["", "", ""];

  if (!!PET_SPECIES_NAME[specie]) return PET_SPECIES_NAME[specie];

  return ["", "", ""];
}

export function getPetGender(gender) {
  if (!gender) return ["", "", ""];

  if (!!PET_GENDERS[gender]) return PET_GENDERS[gender];

  return ["", "", ""];
}

export function getPetCastratedStatus(castrated) {
  if (castrated === true) return PET_CASTRATED['yes'];
  if (castrated === false) return PET_CASTRATED['no'];

  return { male: "-", female: '-' };
}

export function getMedicineFrequency(frequency) {
  if (!!MEDICINE_FREQUENCY[frequency]) return MEDICINE_FREQUENCY[frequency];

  return ["", "", ""];
}
export function getMedicineDuration(duration) {
  if (!!MEDICINE_DURATION[duration]) return MEDICINE_DURATION[duration];

  return ["", "", ""];
}
export function getMedicineMeasure(measure) {
  if (!!MEDICINE_MEASURE[measure]) return MEDICINE_MEASURE[measure];

  return ["", "", ""];
}

export function getMedicinePharmacyType(type) {
  if (!!MEDICINE_PHARMACY_TYPE[type])
    return "Farmácia " + MEDICINE_PHARMACY_TYPE[type][1];

  return "";
}

export const isAbleByStatus = (status, content, that = false) => {
  const department = that || getCurrentDepartment()

  if (department === 'veterinary') {
    const ableStatus = VETERINARY_CARE_ABLE_STATUS[content]
    if (!!ableStatus) {
      return ableStatus.includes(status)
    }
  }

  return false
}


export function getPeriodDiff(period, userOption = {}) {
  const options = {
    from: "month",
    to: "day",
  };

  _.merge(options, userOption);

  const startDate = moment();
  const endDate = moment(startDate).add(period, options.from);
  const periodDiff = endDate.diff(startDate, options.to);

  return periodDiff;
}

export function formatMedicineProps(medicineProps) {
  const dosageInformation = autoFormatMedicineDosageInformation(medicineProps);
  const quantity = formatMedicineQuantity(medicineProps);

  return {
    dosageInformation,
    quantity,
  };
}

export function getArticles(gender) {
  if (!gender) return ["", "", ""];
  if (!!ARTICLES[gender]) return ARTICLES[gender];

  return ["", "", ""];
}
export function getCastrated(gender) {
  if (!gender) return CASTRATED['*'];
  if (!!CASTRATED[gender]) return CASTRATED[gender];
  return CASTRATED['*'];
}

export function getOur(gender) {
  if (!gender) return ["", "", ""];
  if (!!OUR[gender]) return OUR[gender];

  return ["", "", ""];
}

export function getBuddy(gender) {
  if (!gender) return ["", "", ""];
  if (!!BUDDY[gender]) return BUDDY[gender];

  return ["", "", ""];
}

export function getPreviewListPlaceholder(previewListName, values = {}) {
  if (!previewListName || !PREVIEW_LIST_PLACEHOLDER[previewListName]) return '';

  const placeholderContent = PREVIEW_LIST_PLACEHOLDER[previewListName]
  const placeholder = Mustache.render(placeholderContent, values)

  return placeholder
}


export function getStoreName() {
  const storeLocal = localStorage.getItem("STORE")
  if (!storeLocal) return


  const store = storeLocal
    .toLocaleLowerCase()
    .replace("dra mei", "")
    .replace("petland", "");

  const departmentName = getDepartmentName();
  const name = formatName(store);

  const fullName = `${departmentName} ${name}`;

  return {
    name,
    fullName,
  };
}


export function getCurrentDepartment() {
  const department = localStorage.getItem("theme");
  if (!department) return ""

  if (department.toLowerCase() === "dramei") return "veterinary";
  if (department.toLowerCase() === "petland") return "petShop";
}

export function getDepartmentName() {
  const department = getCurrentDepartment();

  if (department === "veterinary") return "Dra.Mei";
  if (department === "petShop") return "Petland";
}

export function getCurrentUserID() {
  const currentEmployeeID = localStorage.getItem("employee");
  const role = localStorage.getItem("role");

  return currentEmployeeID || role || false
}

export function getEmployeeTypeName(that = false) {
  const department = that || getCurrentDepartment();

  if (!!DEPARTMENT_EMPLOYEE_TYPE_NAME[department]) return DEPARTMENT_EMPLOYEE_TYPE_NAME[department];

  return 'Profissional';
}


export function getRecommendationDepartment(data) {
  if (!data) return ''
  return RECOMMENDATION_DEPARTMENT[data.toUpperCase()] ?? formatName(data)
}

export function getRecommendationDepartmentIcon(data) {
  const department = data ? data.toUpperCase() : '*'

  return RECOMMENDATION_DEPARTMENT_ICON[department] ?? RECOMMENDATION_DEPARTMENT_ICON['*']
}

export function dateFormat(date) {
  if (!date) return ''
  const splitDate = date.split("-");
  return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
}

export function getPaginationProps(paginationName) {
  if (!PAGINATION[paginationName]) {
    return PAGINATION["*"];
  }
  return PAGINATION[paginationName];
}

export function findObjectNode(obj, node) {
  if (!obj) return null;
  let deepObj = obj;
  const nodeSplited = node.split(".");

  nodeSplited.forEach((key) => {
    if (deepObj[key] !== null) {
      deepObj = deepObj[key];
    }
  });
  return deepObj;
}

export function getScheduleStatus(scheduledDate) {
  const today = moment().startOf("day");
  const vaccineDate = moment(scheduledDate, "DD/MM/YYYY").startOf("day");
  if (!vaccineDate.isValid()) return false;

  const daysGap = vaccineDate.diff(today, "days");

  if (daysGap > 0) {
    return { status: "scheduled", daysGap };
  }

  if (daysGap === 0) {
    return { status: "today", daysGap };
  }

  if (daysGap < 0) {
    return { status: "delayed", daysGap };
  }
}
export function getProtocolByVaccinesStatus(vaccines) {
  if (!vaccines) return null;

  const isFinished = vaccines.every((vaccine) => vaccine.status === "applied");

  const isEditing = _.some(vaccines, { status: "editing" });
  const isApplying = _.some(vaccines, { status: "applying" });
  const isReprogramming = _.some(vaccines, { status: "reprogramming" });

  const isDelayed = _.some(vaccines, (vaccine) => {
    const { daysGap } = getScheduleStatus(vaccine.scheduledDate);

    return daysGap < 0 && vaccine.status !== "applied";
  });

  if (isEditing) return "editing";
  if (isApplying) return "applying";
  if (isReprogramming) return "reprogramming";
  if (isFinished) return "finished";
  if (isDelayed) return "delayed";

  return "scheduled";
}

export function stringDateFormat(stringDate) {
  const date = stringDate.split("T");

  return dateFormat(date[0]);
}

export function formatCpf(cpf) {
  if (!cpf) return cpf
  const cpfNumbers = cpf
    .toString()
    .replace(/\D+/g, "")

  if (cpfNumbers.length !== 11) return cpf

  return cpfNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}


export function validadeCpf(data) {
  if (!data) return false
  return cpf.isValid(clearNumber(data))
}

export function promiseDelay(millisec) {
  return new Promise(resolve => setTimeout(resolve, millisec));
}

export function joinToSentenceList(list) {
  if (!list) return ''

  const total = list.length

  if (!total) return ''
  if (total === 1) return list[0]

  const listJoined = list.join(', ')
  const sentence = listJoined.substring(0, _.lastIndexOf(listJoined, ',')) + ' e' + listJoined.substring(_.lastIndexOf(listJoined, ',') + 1)

  return sentence
}

export function parseCssDelayToMilliseconds(delay) {
  const time = delay.replace(/\D/g, "");
  if (delay.includes("ms")) {
    return time;
  }
  if (delay.includes("s")) {
    return time * 1000;
  }

  return delay;
}

export function dateFormatedToDB(date) {
  if (!date) return "";

  const splitDate = date.split("/");
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
}

export function getAttribute(attributes, name) {
  return attributes?.filter((attr) => attr.label === name)[0].value;
}

export function formatAttributeValue(value) {
  if (!isNaN(value)) return +value
  if (_.isArray(value)) value = _.flattenDeep(value)[0]

  switch (value) {
    case 'sim':
    case 'Sim':
    case 'vivo':
    case 'Vivo':
      return true
    case 'não':
    case 'nao':
    case 'Não':
    case 'Nao':
    case 'Morto':
    case 'morto':
      return false
    case 'cao':
    case 'cão':
    case 'Cao':
    case 'Cão':
      return 'dog'
    case 'gato':
    case 'Gato':
      return 'cat'
    case 'Macho':
    case 'macho':
      return 'male'
    case 'fêmea':
    case 'femea':
    case 'Femea':
    case 'Fêmea':
      return 'female'
    default:
      return formatName(value)
  };
}



export function getUniqType(extension) {
  return Object.keys(FILE_EXTENSIONS_TYPE).find((type) =>
    _.find(FILE_EXTENSIONS_TYPE[type], (ext) => {
      const regex = new RegExp(ext)
      return regex.test(extension)
    }),
  )
}

export function formatMoney(value, decimals = 2, locale = "pt-BR") {
  value = !value ? 0 : value

  const formattedValue = value.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formattedValue;
}

export function formatWeight(value) {
  const formattedValue = value.toLocaleString("pt-BR", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  return formattedValue;
}


export function downloadBlob(blob, fileName = 'arquivo') {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export function convertFileSize(size) {
  if (!size) return { size: 0, unit: "" };

  const units = ["B", "KB", "MB", "GB", "TB"];

  let fileSize = size;
  let unitIndex = 0;

  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024;
    unitIndex++;
  }

  return { size: formatMoney(fileSize), unit: units[unitIndex] };
}

export function formatFileName(fileName) {
  const name = fileName.replace(/\.[^/.]+$/, "");

  const match = fileName.match(/(?:\.([^.]+))?$/);
  const type = match[1]

  const nameWithSpaces = name.replaceAll("_", " ").replaceAll("-", " ").trim();
  const formatedName = formatName(nameWithSpaces);

  return { name: formatedName, type };
}

export function truncateString(input, maxLength = 30) {
  if (input.length <= maxLength) return input;

  let truncatedString = input.slice(0, maxLength);

  const lastSpaceIndex = truncatedString.lastIndexOf(" ");
  if (lastSpaceIndex >= 0 && lastSpaceIndex < maxLength - 1) {
    truncatedString = truncatedString.slice(0, lastSpaceIndex);
  }

  return truncatedString + "...";
}

export function getMiddleArray(total, center, limit) {
  total = total + 1;
  const pageList = _.range(0, total);

  if (!total) return false;
  if (total <= 1) return false;
  if (total <= limit) return pageList;

  const left = center - Math.round((limit - 1) / 2);
  const right = center + (limit - Math.round((limit - 1) / 2)) - 1;
  return pageList.filter((_item, i) => i >= left && i <= right);
}

export function filterPaginationQuery(obj) {
  const page = !obj ? 0 : obj?.page || 0;
  const perPage = !obj ? 10 : obj?.perPage || 10;

  return { page, perPage };
}

export function moneyType(value) {
  switch (value) {
    case "Estorno":
    case "Cancelamento":
      return "outflow";
    case "Mensalidade":
    case "Banho":
      return "inflow";
    default:
      return "";
  }
}

export function getNumber(value) {
  try {
    const number = value.toString().match(/\d+((.|,)\d+)?/)[0];
    if (Number.isNaN(number)) {
      return 0;
    }
    return parseFloat(value);
  } catch (err) {
    return 0;
  }
}

export function abbreviate(name) {
  if (!name) return name;
  const splitedName = name.trim().split(" ");
  const firtsName = splitedName[0];
  const lastName = splitedName[splitedName.length - 1];
  return `${firtsName} ${lastName[0]}.`;
}

export function formatName(str) {
  if (!str) return str;
  const arr = str.toLowerCase().trim().split(" ");
  return arr
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ").trim();
}

export function splitName(fullName) {
  if (!fullName) return fullName;
  fullName = formatName(fullName);

  const splitedName = fullName.trim().split(" ");

  if (splitedName.length === 1) {
    return {
      fullName: splitedName.join(" "),
      firstname: splitedName[0],
      lastname: "",
    };
  }
  return {
    fullName: splitedName.join(" "),
    firstname: splitedName[0],
    lastname: splitedName.slice(1).join(" "),
  };
}

export function getAcronymName(name) {
  if (!name) return name;
  const splitedname = name.trim().split(" ");
  const firtsName = splitedname[0];
  const lastName = splitedname[splitedname.length - 1];

  if (splitedname.length === 1) return firtsName[0];

  return `${firtsName[0]}${lastName[0]}`;
}

export function randonColor() {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")
    .toUpperCase()}`;
}
export function splitListToPages(list, perPage) {
  if (!list) return [];

  return _.zipObject(
    _.range(0, Math.ceil(list.length / perPage)),
    _.chunk(list, perPage)
  );
}

export function getVaccinationStatusName(status) {
  if (!status) return ["", "", ""];

  if (!!VACCINATION_STATUS_NAME[status]) return VACCINATION_STATUS_NAME[status];

  return ["", "", ""];
}

export function getVaccinationStatusFields(status) {
  if (!status) return "";

  if (!!VACCINATION_STATUS_FIELDS[vaccine.status]) return VACCINATION_STATUS_FIELDS[vaccine.status]

  return "";
}

export function getDepartmentNameValue(value) {
  if (!value) return "";

  if (!!DEPARTMENT_NAME_VALUE[value]) return DEPARTMENT_NAME_VALUE[value];

  return "";
}

export function getDepartmentValueName(name) {
  if (!name) return "";

  if (!!DEPARTMENT_VALUE_NAME[name]) return DEPARTMENT_VALUE_NAME[name];

  return "";
}

export function getServiceStatusValue(status) {
  if (!status) return "";

  if (!!SERVICE_STATUS[status.toLowerCase()]) return SERVICE_STATUS[status.toLowerCase()];

  return "";
}

export function getVeterinaryCareStatusName(status) {
  if (!status) return "";

  if (!!VETERINARY_CARE_STATUS[status.toLowerCase()]) return VETERINARY_CARE_STATUS[status.toLowerCase()];

  return "";
}

export function getVeterinaryCareNameStatus(status) {
  if (!status) return "";

  if (!!VETERINARY_CARE_STATUS_NAME[status]) return VETERINARY_CARE_STATUS_NAME[status].toUpperCase();

  return "";
}

export function getVeterinaryCareStatusPlaceholder(status) {
  if (!status) return "";

  if (!!VETERINARY_CARE_STATUS_PLACEHOLDER[status]) return VETERINARY_CARE_STATUS_PLACEHOLDER[status];

  return "";
}

export function getUserType(type = false) {
  if (!!type) {
    if (!!USER_TYPE_NAME_VALUE[type]) return USER_TYPE_NAME_VALUE[type];
    return USER_TYPE_NAME_VALUE['Gerente']
  }

  const currentUserType = localStorage.getItem('type');

  if (!!USER_TYPE_NAME_VALUE[currentUserType]) return USER_TYPE_NAME_VALUE[currentUserType];

  return ''
}

export function getUserTypeLevel(type = false) {
  if (!!type) {
    if (!!USER_TYPE_NAME_LEVEL[type]) return USER_TYPE_NAME_LEVEL[type];
    return '0'
  }

  const currentUserType = localStorage.getItem('type');
  if (!!USER_TYPE_NAME_VALUE[currentUserType]) return USER_TYPE_NAME_VALUE[currentUserType];
  return '0'
}


export function formatPetServicesPackage(services) {
  if (_.isEmpty(services) || !_.isArray(services)) return services

  return services.map(service => ({
    id: service.serviceId,
    name: formatName(service.name),
    quantityAvailable: service.quantityAvailable,
    priceWithDiscount: service.priceWithDiscount,
    status: getServiceStatusValue(service.status),
    department: getDepartmentNameValue(service.department),
  }))

}
export function formatCellPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return "";
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{1})?(\d{4})(\d{4})$/);

  if (match) {
    const part1 = match[1];
    const part2 = match[2] ? ` ${match[2]}` : "";
    const part3 = match[3];
    const part4 = match[4];

    return `(${part1}) ${part2} ${part3}-${part4}`;
  } else {
    return phoneNumberString;
  }
}

export function checkValue(value, regex) {
  return regex.test(value);
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function camelize(str) {
  return slugify(str, " ")
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, "");
}

export function compareObj(obj1, obj2) {
  const obj1String = JSON.stringify(obj1);
  const obj2String = JSON.stringify(obj2);

  return obj1String === obj2String;
}
export function secondsToHms(d, format = "HH:ii") {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hour = h > 0 ? h.toString().padStart(2, "0") : "00";
  const minute = m > 0 ? m.toString().padStart(2, "0") : "00";
  const second = s > 0 ? s.toString().padStart(2, "0") : "00";

  const values = { hour, minute, second };
  const formated = format
    .replace("HH", hour)
    .replace("ii", minute)
    .replace("ss", second);

  return { values, formated };
}

export function HmsToseconds(time) {
  if (!time) return time;
  time = time.replace(/[^0-9:]/g, "0");
  const hms = time.split(":");

  switch (hms.length) {
    case 3:
      return +hms[0] * 60 * 60 + +hms[1] * 60 + +hms[2];
    case 2:
      return +hms[0] * 60 * 60 + +hms[1] * 60;
    case 1:
      return +hms[0] * 60 * 60;
    default:
      return 0;
  }
}

export function sizeBetween(sizes, find) {
  const f = find.toFixed(1);
  const sizeData = sizes.find((s) => {
    const { range } = s;
    if (range.length > 1) {
      if (f >= range[0] && f <= range[1]) return true;
    }
    if (range.length === 1) {
      if (f >= range[0]) return true;
    }
    return false;
  });
  return sizeData ? sizeData.name : "err";
}

export function limtShowNumber(number, limit, sufix = "+") {
  if (+number > limit) {
    return `${sufix}${limit}`;
  }
  return number;
}

export function jsonToQuery(params) {
  return new URLSearchParams(params).toString();
}

export function calendarStatusIfTheme(theme, status) {
  if (CALENDAR.status[status]) {
    return CALENDAR.status[status];
  }
  return CALENDAR.status[theme][status];
}
export function getPetAttribute(attributes, find) {
  if (!attributes.length) return { data: [] };
  return attributes.find((attribute) => attribute.label === find);
}

export function clearNumber(stringNumber) {
  if (!stringNumber) return;
  return stringNumber.toString().replace(/\D/g, "");
}

export function checkPetRegistration(petData) {
  const requiredRegistrationFiledError = PET_COMPLETE_REGISTRATION_FIELDS.find(field => {
    const fieldContent = _.get(petData, field)
    if (_.isEmpty(fieldContent)) {
      if (field.includes('attributes')) {
        const attribute = field.split('.')
        const attributeValue = getAttribute(petData.attributes, attribute[1])
        return _.isEmpty(attributeValue)
      }
      return true
    }

    return false
  })

  return !requiredRegistrationFiledError
}

export function mountAddress(addressObj) {
  const fullAddress = []
  const blockAddress = {
    street: [],
    neighborhood: [],
    city: [],
  }

  if (!!addressObj.street) fullAddress.push(addressObj.street)
  if (!!addressObj.number) fullAddress.push(', ', addressObj.number)
  if (!!addressObj.complement) fullAddress.push('/', addressObj.complement)
  if (!!addressObj.neighborhood) fullAddress.push(' - ', addressObj.neighborhood)
  if (!!addressObj.city) fullAddress.push(', ', addressObj.city)
  if (!!addressObj.uf) fullAddress.push(' - ', addressObj.uf)
  if (!!addressObj.postalCode) fullAddress.push(', ', addressObj.postalCode)

  if (!!addressObj.street) blockAddress.street.push(addressObj.street)
  if (!!addressObj.number) blockAddress.street.push(', ', addressObj.number)
  if (!!addressObj.complement) blockAddress.street.push('/', addressObj.complement)
  if (!!addressObj.neighborhood) blockAddress.neighborhood.push(addressObj.neighborhood)
  if (!!addressObj.postalCode) blockAddress.neighborhood.push(', ', addressObj.postalCode)
  if (!!addressObj.city) blockAddress.city.push(addressObj.city)
  if (!!addressObj.uf) blockAddress.city.push(' - ', addressObj.uf)

  return {
    full: fullAddress.join(''),
    blocks: {
      street: blockAddress.street.join(''),
      neighborhood: blockAddress.neighborhood.join(''),
      city: blockAddress.city.join(''),
    }
  }
}

export function formatZipCode(zipCode) {
  if (!zipCode) return zipCode;
  const cleaned = clearNumber(zipCode).substring(0, 8)

  return cleaned.slice(0, 5) + '-' + cleaned.slice(5);
}
